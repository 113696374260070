// const initialState = {
//     balanceKCash: 0,
//     cashOut: 0,
//     earnedKCash: 0,
//     kcashDetailList: [],
//     cpgnDataList: [],
//     currentSelectCpgnId: ''
// }

// const kwikCash = (state = initialState, action) => {
//     switch (action.type) {
//         case 'GET_KWIKCASH_INFO':
//             let { balanceKCash, cashOut, earnedKCash, kcashDetailList } = action.kwikCashInfo;
//             let cpgnId = action.cpgnId;
//             return Object.assign({}, state, {
//                 balanceKCash,
//                 cashOut,
//                 earnedKCash,
//                 kcashDetailList,
//                 currentSelectCpgnId: cpgnId
//             })
//         case 'KWIKCASH/SET_CPGNDATA_LIST':
//             let cpgnDataList = action.cpgnDataList;
//             return Object.assign({}, state, {
//                 cpgnDataList,
//                 currentSelectCpgnId: cpgnDataList[0] ? cpgnDataList[0].cpgnId : ''
//             })
//         // case 'KWIKCASH/UPDATE_SELECT_CPGNID':
//         //     return Object.assign({}, state, {
//         //         currentSelectCpgnId: action.cpgnId
//         //     })
//         default:
//             return state
//     }
// }

import { setKcashTotalHandle } from "../../action/kwikCash/kwikCash"

const initialState = {
    kcVuKCashTotalCommandList: []
}

const kwikCash = (state = initialState, action) => {
    switch (action.type) {
        case setKcashTotalHandle:
        return {
            ...state,
            kcVuKCashTotalCommandList: action.kcVuKCashTotalCommandList || []
        }
        default:
            return state
    }
}

export default kwikCash