import { combineReducers } from "redux";
import productList from "./shopping/productList";
import productDetail from "./shopping/productDetail";
import checkoutPayments from "./shopping/checkoutPayments";
import orderHistory from "./shopping/orderHistory";
import checkoutAddress from "./shopping/checkoutAddress";
import smartLinkCreate from "./smartLink/smartLinkCreate";
import smartLinkGenerate from "./smartLink/smartLinkGenerate";
import kwikCash from "./kwikCash/kwikCash";
import campaigns from "./campaigns/campaigns";
import language from "./language/language";

const appReducer = combineReducers({
  productList,
  productDetail,
  checkoutPayments,
  smartLinkCreate,
  smartLinkGenerate,
  orderHistory,
  checkoutAddress,
  kwikCash,
  campaigns,
  language,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined; //清空store
  }
  return appReducer(state, action);
};

export default rootReducer;
