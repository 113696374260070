import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import getLanguage from '../utils/getLanguage'

import en_US from './en_US'
import ja_JP from './ja_JP'
import fr_FR from './fr_FR'
import de_DE from './de_DE'
import en_GB from './en_GB'
import es_ES from './es_ES'
import es_US from './es_US'
import fr_CA from './fr_CA'
import it_IT from './it_IT'
import zh_CN from './zh_CN'
import zh_TW from './zh_TW'

const resources = {
  en_us: en_US,
  ja_jp: ja_JP,
  fr_fr: fr_FR,
  en_us: en_US,
  zh_ch: zh_CN,
  zh_tw: zh_TW,
  es_mx: es_ES,
  it_it: it_IT,
  de_de: de_DE,
};

// sessionStorage.getItem('currentSelectCountryCode') || sessionStorage.setItem('currentSelectCountryCode', 'USA');
// sessionStorage.getItem('language') || sessionStorage.setItem('language', 'en_us');

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLanguage(),
    fallbackLng: 'en_us',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;