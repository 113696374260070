import React, { useEffect, useState } from "react";
import "./style.scss";
import Search from "./Search";
import LanguageSelect from "./languageSelect";
import { message, Select } from "antd";
import { getKcVendorCnst } from "../../../requests/product";

function TopToolbar({
  handleSearch = undefined,
  setLanguage = undefined,
  vendorList = undefined,
  vendorId,
  setVendorId,
  disabled = false
}) {
  const [searchText, setSearchText] = useState("");
  const [currentVendorId, setCurrentVendorId] = useState(vendorId);

  useEffect(() => {
    setCurrentVendorId(vendorId);
  }, [vendorId]);
  const search = () => {
    handleSearch(searchText);
  };
  const [languageInfo, setLanguageInfo] = useState({
    country: sessionStorage.getItem("currentSelectCountryCode"),
    lang: sessionStorage.getItem("language")
  });

  const setLanguageForToolbar = (country, lang) => {
    setLanguageInfo({
      country: country,
      lang: lang
    });
    setLanguage(country, lang);
  };

  const vendorSelectChange = value => {
    // currentVendorId.current = (value === '0' ? '' : value);
    let selectVendorId = value;
    setCurrentVendorId(selectVendorId);
    setVendorId(selectVendorId);
    sessionStorage.setItem("vendorAdminSelectVendorId", selectVendorId);
    if (handleSearch !== undefined) {
      handleSearch("");
    }
  };

  return (
    <div className="vendor-top-toolbar">
      <div className="top-toolbar-left-area">
        {handleSearch && (
          <Search setSearchText={setSearchText} onSearch={search} />
        )}
      </div>
      <div className="toolbar-right-area">
        {vendorList && sessionStorage.getItem("loginType") === "0" && (
          <div className="select-vendor">
            <Select
              showSearch
              disabled={disabled}
              value={currentVendorId}
              style={{ minWidth: 200 }}
              onChange={vendorSelectChange}
              filterOption={(input, option) =>
                option.children?.toLowerCase().indexOf(input?.toLowerCase()) >=
                0
              }
            >
              {vendorList.map((vendor, index) => {
                return (
                  <Select.Option key={index} value={vendor.vid}>
                    {vendor.vName === "KC Admin" ? "All vendors" : vendor.vName}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        )}
        <div className="toolbar-language-area">
          {setLanguage && (
            <LanguageSelect
              language={languageInfo.lang}
              countryCode={languageInfo.country}
              setLanguage={setLanguageForToolbar}
            />
          )}
        </div>
        {/*<div className="toolbar-briefcase-area">*/}
        {/*    <img src={require("../../../../images/your-product.png")}/>*/}
        {/*    <div className="briefcase-yellow-point"/>*/}
        {/*</div >*/}
        {/*<div className="toolbar-portrait-area">*/}
        {/*    <img src={require("../../../../images/head.png")}/>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export default TopToolbar;
