import React from 'react'
import ReactDOM from 'react-dom';
import './style.scss'

class HintModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    closeBtnClick() {
        this.props.onClose && this.props.onClose();
    }
    render() {
        return ReactDOM.createPortal(
            this.props.visible === true ?
                <div className="hintModalComponent">
                    <div className="inner">
                        <div className="rightIcon">
                            <img src={require('./images/right.png')} alt="" />
                        </div>
                        <div className="mess">
                            {this.props.message}
                        </div>
                    </div>
                </div> : null,
            document.body
        );
    }
}

export default HintModal