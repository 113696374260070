import React, { useState } from 'react';
import './style.scss';
import { Select } from 'antd';
import { Toast } from "antd-mobile";
import MoreActionsAlert from "../../../containers/Ambassadors/modal/MoreActionsAlert";
import { useTranslation } from "react-i18next";
import PermissionsControl from '../../../../components/PermissionsControl';


function FiltersState({ campaigns, campaignId, campaignChange, assignStatus, removeAmbassadorsData,
  moreActionAlertStep, setMoreActionAlertStep, selectedCount = 0 }) {
  const { t } = useTranslation();
  return (
    <div className="ambassadors-filters-state">
      <div className="selected-count-num">{selectedCount}</div>
      <div className="selected-text">{t('AMBASSADORS_FILTER_SELECTED_TEXT')}</div>
      <PermissionsControl name="Operate Ambassadors">
        <a onClick={() => {
          if (selectedCount > 0) {
            setMoreActionAlertStep(1)
          } else {
            Toast.info(t('AMBASSADORS_TOAST_SELECT_AMBASSADORS'), 2);
          }
        }}
          className="more-action">
          <span>{t('AMBASSADORS_BUTTON_MORE_ACTIONS')}</span>
          <img src={require("../../../../images/share-select-downarrow.png")} alt="" />
        </a>
      </PermissionsControl>
      <span className="campaigns-text">{t('AMBASSADORS_FILTER_CAMPAIGNS')}</span>
      <Select
        className="Filters-state-campaigns"
        value={campaignId}
        onChange={(value) => { campaignChange(value) }}>
        <Select.Option value={0}>{t('AMBASSADORS_FILTER_ALL_TEXT')}</Select.Option>
        {
          campaigns.map(item => {
            return <Select.Option key={item.id} value={item.id} >{item.name}</Select.Option>
          })
        }
      </Select>
      <MoreActionsAlert assignStatus={assignStatus} moreActionAlertStep={moreActionAlertStep}
        setMoreActionAlertStep={setMoreActionAlertStep}
        removeAmbassadorsData={removeAmbassadorsData} />
    </div>
  )
}

export default FiltersState;