let context = process.env.REACT_APP_WEB_CONTEXT;

function getShareLinkPath(url) {
    // let paramIndex = url.indexOf('?');
    // if (url.indexOf('/ketone') > -1) {
    //     return window.GLOBAL.ketone.shareLinkPath[context] + url.substr(paramIndex);
    // } else {
        return window.location.origin + url;
    // }
}
export default getShareLinkPath