import React, { useState } from 'react';
import './style.scss';
import { Modal, Radio } from 'antd';
import { CloseOutlined } from "@ant-design/icons";
import { AMBASSADOR_STATUS } from "../../index";
import { useTranslation } from "react-i18next";
import PermissionsControl from '../../../../../components/PermissionsControl';

function MoreActionsAlert({ assignStatus, moreActionAlertStep, setMoreActionAlertStep, removeAmbassadorsData }) {
  const { t } = useTranslation();
  const statusList = Object.entries(AMBASSADOR_STATUS).map(([key, value]) => {
    return { id: key * 1, name: value }
  })
  const [status, setStatus] = useState();
  const modalClose = () => {

    setMoreActionAlertStep(0);
  }

  const removeAmbassadors = () => {
    removeAmbassadorsData();
  }

  const assignStatusAlert = () => {
    setMoreActionAlertStep(2);
  }

  const statusChange = (e) => {
    setStatus(e.target.value);
  }
  const saveStatus = () => {
    assignStatus(status);
  }

  function buttonHandler() {
    return (
      <div className="button-area">
        <PermissionsControl name="Operate Ambassadors"><a onClick={removeAmbassadors}>{t('AMBASSADORS_BUTTON_REMOVE_AMBASSADORS')}</a></PermissionsControl>
        <PermissionsControl name="Operate Ambassadors"><a onClick={assignStatusAlert}>{t('AMBASSADORS_BUTTON_ASSIGN_STATUS')}</a></PermissionsControl>
      </div>
    )
  }

  function assignStatusModal() {
    return (
      <div className="assign-status-area">
        <div className="close-btn">
          <div className="close" onClick={modalClose}>
            <CloseOutlined />
          </div>
        </div>
        <div className="assign-a-status-title">Assign a Status</div>
        <div className="assign-a-status-list">
          <Radio.Group onChange={(e) => { statusChange(e) }} >
            {
              statusList && statusList.map(item => {
                return (
                  <Radio key={item.id} value={item.id}>{item.name}</Radio>
                )
              })
            }
          </Radio.Group>
        </div>
        <div className="assign-a-status-button-area">
          <a onClick={modalClose}>Cancel</a>
          <a onClick={saveStatus}>Done</a>
        </div>
      </div>
    )
  }
  return (
    <div>
      {
        moreActionAlertStep === 1 && <Modal
          visible={moreActionAlertStep !== 0}
          onCancel={modalClose}
          centered={true}
          footer={null}
          closable={false} maskClosable={true}
          wrapClassName="more-actions-modal"
          width={moreActionAlertStep === 2 ? 372 : 191}
        >
          {
            buttonHandler()
          }
        </Modal>
      }

      {
        moreActionAlertStep === 2 && <Modal
          visible={moreActionAlertStep !== 0}
          onCancel={modalClose}
          centered={true}
          footer={null}
          closable={false} maskClosable={true}
          wrapClassName="more-actions-modal"
          width={moreActionAlertStep === 2 ? 372 : 191}
        >
          {
            assignStatusModal()
          }
        </Modal>
      }
    </div>
  );
}

export default MoreActionsAlert;