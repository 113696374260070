import qs from "qs";
import service from "../../utils/service";

// 获取campaigns列表
// export const getCampaignsList = (data) => {
//     return service.get('/vendor/getKcVuCpgn', {
//         params: data
//     });
// }
// 获取campaigns列表
export const getCampaignsList = data => {
  return service.post("/campaign/getCnstCampaign?vendorId=" + data.vendorId, {
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};
// 获取campaigns列表带token
export const getCnstCampaign = data => {
  return service.post(
    "/vendor/customer/getCnstCampaign?vendorId=" + data.vendorId,
    {},
    {
      headers: {
        token: sessionStorage.getItem("vendorToken")
      }
    }
  );
};
// 保存campaigns
export const saveCnstCampaign = data => {
  return service.post("/campaign/saveCnstCampaign", data, {
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};
// 更新campaigns
export const updateCnstCampaign = data => {
  return service.post("/campaign/updateCnstCampaign", data, {
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};
// 删除campaigns
export const deleteCnstCampaign = data => {
  return service.post("/campaign/deleteCnstCampaign?cpgnId=" + data.cpgnId, {
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};
// 获取绑定产品列表
export const getVuCpgnProd = data => {
  return service.post(
    "/campaign/getVuCpgnProd?vendorId=" + data.vendorId,
    {},
    {
      headers: {
        token: sessionStorage.getItem("vendorToken")
      }
    }
  );
};
// 判断是否可以修改产品cpgn绑定
export const getIsCanUpdateCpgnProduct = data => {
  return service.get(
    `/vendor/getIsCanUpdateCpgnProduct?cpgnId=${data.cpgnId}&oldCpgnId=${data.oldCpgnId}&productId=${data.productId}`,
    {
      headers: {
        token: sessionStorage.getItem("vendorToken")
      }
    }
  );
};

export const getIsCanUpdateCpgnProductNull = data => {
  return service.get(
    `/vendor/getIsCanUpdateCpgnProduct?cpgnId=${data.cpgnId}&productId=${data.productId}`,
    {
      headers: {
        token: sessionStorage.getItem("vendorToken")
      }
    }
  );
};
// 修改产品cpgn绑定
export const saveOrUpdateCpgnProd = data => {
  return service.post(
    `/campaign/saveOrUpdateCpgnProd?cpgnId=${data.cpgnId}&productId=${data.productId}`,
    {
      headers: {
        token: sessionStorage.getItem("vendorToken")
      }
    }
  );
};
// 删除Cpgn
export const deleteCpgnProduct = data => {
  return service.post(
    `/campaign/deleteCpgnProduct?cpgnId=${data.cpgnId}&productId=${data.productId}`,
    {
      headers: {
        token: sessionStorage.getItem("vendorToken")
      }
    }
  );
};
// 获取添加campaign对应产品
export const getVuVavilProducts = vendorId => {
  return service.post(
    `/vendor/product/getVuVavilProducts?vendorId=${vendorId || ""}`,
    {},
    {
      headers: {
        token: sessionStorage.getItem("vendorToken")
      }
    }
  );
};

export const updateCnstCampaignSimpleInfo = data => {
  return service.post("/campaign/updateCnstCampaignSimpleInfo", data, {
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};
