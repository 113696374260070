import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import KCApp from "./KCApp";
import store from "./store";
import { Provider } from "react-redux";
import "./i18n";
import "antd/dist/antd.css";
import "./index.scss";
import CartContextProvider from "./context/CartContext";
import BrandContextProvider from "./context/BrandContext";
import KwikcashContextProvider from "./context/KwikcashContext";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";


// TODO: Add the client ID to some sort of secret management

ReactDOM.render(
  <Provider store={store}>
    <CartContextProvider>
      <BrandContextProvider>
        <KwikcashContextProvider>
          <KCApp />
        </KwikcashContextProvider>
      </BrandContextProvider>
    </CartContextProvider>
  </Provider>,
  document.getElementById("root")
);
