import React, { useState } from "react";
import "./style.scss";
import "../public.scss";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Modal,
  Radio,
  Select,
  Upload,
  Switch
} from "antd";
import { useTranslation } from "react-i18next";
import { Toast } from "antd-mobile";
function InviteUsers({
  showInviteUsesModal,
  modalClose,
  submitHandle,
  submitData,
  setSubmitData,
  prodImageBeforeUpload,
  statusList
}) {
  const { t } = useTranslation();
  const { Dragger } = Upload;

  const emailsHandleChange = value => {
    setSubmitData({ ...submitData, emails: value });
  };

  const changeSwitch = e => {
    setSubmitData({ ...submitData, isSendEmail: e });
  };

  const statusChange = e => {
    setSubmitData({ ...submitData, status: e.target.value });
  };
  const campaignsSelectChange = (e, id) => {
    const newCampaigns = submitData.campaigns.map(item => {
      if (item.id === id) {
        item.selected = e.target.checked;
      }
      return item;
    });
    setSubmitData({ ...submitData, campaigns: newCampaigns });
  };

  const onCancelHandle = () => {
    modalClose();
  };

  const onInviteHandle = () => {
    if (submitData.emails.length === 0) {
      Toast.info(t("AMBASSADORS_TOAST_ADD_EMAIL"), 2);
    } else {
      submitHandle();
    }
  };
  return (
    <div>
      <Modal
        visible={showInviteUsesModal}
        onCancel={modalClose}
        footer={null}
        closable={false}
        maskClosable={true}
        wrapClassName="invite-users-modal"
        width={768}
      >
        <div className="invite-users-title">
          {t("AMBASSADORS_BUTTON_INVITE_USERS")}
        </div>
        <div className="invite-users-line" />
        <div className="invite-users-modal-body">
          <div className="invite-users-to">To</div>
          <div className="invite-users-email-select">
            <Select
              mode="tags"
              style={{
                width: "623px"
              }}
              placeholder="Enter Emails"
              onChange={emailsHandleChange}
              value={submitData.emails}
              open={false}
            />
          </div>
          <div className="left-right-layout">
            <div className="modal-public campaigns-name-title">
              {t("AMBASSADORS_TITLE_SELECT_CAMPAIGNS")}
            </div>
            <div className="modal-public campaigns-name-text">
              {t("AMBASSADORS_TITLE_CAMPAIGN_NAME")}
            </div>
            {/*<div className="modal-public campaigns-status-message">*/}
            {/*    Set the default status of those who come in through your link. You can adjust at any time or adjust an individual ambassador on the “Ambassador” page.*/}
            {/*</div>*/}
            <div className="modal-public campaigns-item">
              {submitData.campaigns &&
                submitData.campaigns.map(item => {
                  return (
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      key={item.id}
                    >
                      <Checkbox
                        style={{ minWidth: 200 }}
                        checked={item.selected}
                        onChange={e => {
                          campaignsSelectChange(e, item.id);
                        }}
                      >
                        {item.name}
                      </Checkbox>
                      <Checkbox
                        style={{ marginLeft: 20 }}
                        checked={item.inviteOption == 1}
                        onChange={e => {
                          const tempObj = Object.assign({}, submitData);
                          tempObj.campaigns.map(campaign => {
                            if (campaign.id == item.id) {
                              campaign.inviteOption = e.target.checked ? 1 : 0;
                            }
                          });
                          setSubmitData(tempObj);
                        }}
                      >
                        With 'Invite a Friend' Option
                      </Checkbox>
                    </div>
                  );
                })}
            </div>
            <div className="modal-public assign-a-status">
              {t("AMBASSADORS_BUTTON_ASSIGN_STATUS")}
            </div>
            <div className="modal-public status-item">
              <Radio.Group
                onChange={e => {
                  statusChange(e);
                }}
                value={submitData.status * 1}
              >
                {statusList &&
                  statusList.map(item => {
                    return (
                      <Radio key={item.id} value={item.id}>
                        {item.name}
                      </Radio>
                    );
                  })}
              </Radio.Group>
            </div>
            <div className="modal-public campaigns-name-title">
              {t("AMBASSADORS_TITLE_SEND_EMAIL_TO_AMB")}
            </div>
            <div className="modal-public campaigns-name-text">
              {t("AMBASSADORS_MESSAGE_SEND_EMAIL_TO_AMB")}
            </div>
            <Switch
              onChange={changeSwitch}
              defaultChecked={true}
              checked={submitData.isSendEmail}
              className={
                submitData.isSendEmail ? "switchStyle on" : "switchStyle off"
              }
            />
            <div className="left-image-upload">
              <div className="general-form">
                <span className="upload-image-title">
                  {t("AMBASSADORS_TITLE_UPLOAD_IMAGE")}
                </span>
                <div className="upload-image-message">
                  {t("AMBASSADORS_TITLE_UPLOAD_IMAGE_TEXT")}
                </div>
                <Form>
                  {submitData.imageUrl && (
                    <Button
                      onClick={() => {
                        setSubmitData({ ...submitData, imageUrl: "" });
                      }}
                      className="black-btn"
                    >
                      {t("AMBASSADORS_BUTTON_REMOVE")}
                    </Button>
                  )}
                  <Form.Item className="card">
                    <Dragger
                      className="image-upload"
                      action=""
                      showUploadList={false}
                      beforeUpload={prodImageBeforeUpload}
                    >
                      {submitData.imageUrl === "" ? (
                        <>
                          <img
                            src={require("../img/image-upload.svg")}
                            alt=""
                          />
                          <div className="image-upload-text1">
                            {t("AMBASSADORS_TITLE_UPLOAD_TEXT")}
                          </div>
                          <div className="image-upload-text2">
                            {t("AMBASSADORS_TITLE_DROP_TIPS")}
                          </div>
                        </>
                      ) : (
                        <img
                          src={submitData.imageUrl}
                          alt=""
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      )}
                    </Dragger>
                  </Form.Item>
                  <p className="help-text">
                    {t("AMBASSADORS_TITLE_UPLOAD_SUGGEST_1")}
                    <br />
                    {t("AMBASSADORS_TITLE_UPLOAD_SUGGEST_2")}
                  </p>
                </Form>
              </div>
            </div>
            <div className={"modal-public buttons-area"}>
              <a onClick={onCancelHandle}>
                <div className={"button-cancel"}>
                  {t("AMBASSADORS_BUTTON_CANCEL")}
                </div>
              </a>
              <a onClick={onInviteHandle}>
                <div className={"button-copy"}>
                  <div className="button-name">
                    <img src={require("../img/invite.svg")} />
                    {t("AMBASSADORS_BUTTON_INVITE")}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default InviteUsers;
