const initialState = {
  lang: sessionStorage.getItem("language") || "en_us",
  country: sessionStorage.getItem("currentSelectCountryCode") || "USA",
};

export default function language(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_LANG":
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
