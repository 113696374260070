import { campaignsUpdateCurrentShowIndex } from "../../action/campaigns/campaigns";

const initialState = {
  currentIndex: -1,
};

const campaigns = (state = initialState, action) => {
  switch (action.type) {
    case campaignsUpdateCurrentShowIndex:
      let { currentIndex } = action;
      return {
        ...state,
        currentIndex,
      };
    default:
      return state;
  }
};

export default campaigns;
