const initialState = {
    kcKwikLinkCommand: {},
    vendorCommandList: [],
    vuProductMasterByIdsMap: {},
    vuProductVariantMap: {}
}

const smartLinkGenerate = (state = initialState, action) => {
    let current;
    switch (action.type) {
        case 'SMARTLINK_GET_MANAGE_LIST':
            let { smartLinkListBase } = action;
            current = action.current;
            return {
                ...state,
                smartLinkListBase,
                current
            }
        case 'SMARTLINK_GET_MANAGE_DETAIL':
            let { kcKwikLinkCommand, vendorCommandList, vuProductMasterByIdsMap, vuProductVariantMap } = action;
            return {
                ...state,
                kcKwikLinkCommand,
                vendorCommandList,
                vuProductMasterByIdsMap,
                vuProductVariantMap
            }
        case 'SMARTLINK_TOGGLE_SELECT_INFO':
            let { index } = action;
            return {
                ...state,
                current: index
            }
        default:
            return state
    }
}

export default smartLinkGenerate