const initialState = {
    dataList: []
}

const smartLinkCreate = (state = initialState, action) => {
    switch (action.type) {
        case 'SMARTLINK_GET_PRODUCT_LIST':
            let { dataList } = action;
            return {
                ...state,
                dataList
            }
        case 'SMART_LINK_TOGGLE_CLASS_SHOW':
            let {country, vendorIndex} = action;
            let _dataList = Object.assign([], state.dataList);
            _dataList[country][vendorIndex].show = !!_dataList[country][vendorIndex].show;
            _dataList[country][vendorIndex].show = !_dataList[country][vendorIndex].show;
            return Object.assign({}, state, {
                dataList: _dataList
            });
        default:
            return state
    }
}

export default smartLinkCreate