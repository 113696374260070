import { getCustomerKCashInfo, getCpgnList, getKcVuKcashTotal } from "../../requests/kwikCash";
import sessionCache from "../../utils/sessionCache";
import { Toast } from 'antd-mobile'

// // 获取kwikCash信息
// export const getCpgnListAction = (callback) => {
//     return (dispath) => {
//         let data = {
//             custId: sessionCache().getItem('custId')
//         }
//         Toast.loading('Loading...', 600);
//         getCpgnList(data).then(
//             res => {
//                 if (res.data.errorCode === "200") {
//                     let response = Object.values(res.data.response);
//                     response = response.filter(d => d.countryCode === sessionCache().getItem('currentSelectCountryCode'));
//                     callback(response[0] ? response[0].cpgnId : '')
//                     dispath({
//                         type: 'KWIKCASH/SET_CPGNDATA_LIST',
//                         cpgnDataList: response
//                     })
//                 } else {
//                     Toast.info(res.data.errorMessage, 2);
//                 }
//             }
//         )
//     }
// }
// // 获取cpng数据列表
// export const getCustomerKCashInfoAction = (cpgnId) => {
//     return (dispath) => {
//         if (cpgnId === '') {
//             Toast.hide();
//             let info = {
//                 balanceKCash: 0,
//                 cashOut: 0,
//                 earnedKCash: 0,
//                 kcashDetailList: [],
//             };
//             sessionCache().setItem('kwikcashBalance', info.balanceKCash);
//             dispath({
//                 type: 'GET_KWIKCASH_INFO',
//                 kwikCashInfo: info,
//                 cpgnId
//             })
//             return;
//         }
//         let data = {
//             custId: sessionCache().getItem('custId'),
//             cpgnId
//         }
//         getCustomerKCashInfo(data).then(
//             res => {
//                 Toast.hide();
//                 if (res.data.errorCode === "200") {
//                     let response = res.data.response;
//                     sessionCache().setItem('kwikcashBalance', response.balanceKCash);
//                     dispath({
//                         type: 'GET_KWIKCASH_INFO',
//                         kwikCashInfo: response,
//                         cpgnId
//                     })
//                 } else {
//                     Toast.info(res.data.errorMessage, 2);
//                 }
//             }
//         )
//     }
// }

// 获取kwikCash数据
export const setKcashTotalHandle = 'KWIKCASH/SET_KCASH_TOTAL';
export const getKcVuKcashTotalAction = () => {
    return (dispath) => {
        let data = {
            custId: sessionCache().getItem('custId')
        }
        Toast.loading('Loading...', 600);
        getKcVuKcashTotal(data).then(
            res => {
                Toast.hide();
                if (res.data.errorCode === "200") {
                    let kcVuKCashTotalCommandList = res.data.kcVuKCashTotalCommandList;
                    let totalBalance = kcVuKCashTotalCommandList.filter((d) => {return d.kcStatus === 20}).length > 0 ? kcVuKCashTotalCommandList.filter((d) => {return d.kcStatus === 20})[0].totalBalance : 0;
                    sessionCache().setItem('kwikcashBalance', totalBalance);
                    dispath({
                        type: setKcashTotalHandle,
                        kcVuKCashTotalCommandList
                    })
                } else {
                    Toast.info(res.data.errorMessage, 2);
                }
            }
        )
    }
}