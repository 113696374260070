import React from "react";
import { createContext, useState } from "react";
import { useSelector } from "react-redux";
import { getAllProductList } from "../requests/shopping";
import { Toast } from "antd-mobile";
import sessionCache from "../utils/sessionCache";

export const BrandContext = createContext({
  brands: [],
  brand: {},
  updateBrand: () => { },
});

const BrandContextProvider = (props) => {
  const [brand, setBrand] = useState({ vid: 0 });
  const [brands, setBrands] = useState([{ vid: 1 }, { vid: 2 }]);
  // const languageInfo = useSelector((state) => state.language);
  const languageInfo = {
    country: sessionStorage.getItem('currentSelectCountryCode'),
    lang: sessionStorage.getItem('language')
  }
  const updateBrands = async (brandId) => {
    let updatedBrand;
    const res = await getAllProductList();
    if (res.data.errorCode === "200") {
      let productObj = res.data.response.vendorListCommandList;
      console.log(productObj);
      let countryCodeCommandList = res.data.response.countryCodeCommandList;
      setBrands(productObj[languageInfo.country ?? "USA"]);
      // sessionStorage.setItem("currentSelectCountryCode", 'USA');
      // setBrands(productObj[countryCodeCommandList[0].countryCode]);
      // sessionStorage.setItem("currentSelectCountryCode", countryCodeCommandList[0].countryCode);
      // updatedBrand = productObj[countryCodeCommandList[0].countryCode]
      updatedBrand = productObj[languageInfo.country ?? "USA"].filter((br) => {
        return br.vid === parseInt(brandId);
      })[0];
    } else {
      Toast.info(res.data.errorMessage, 2);
    }

    if (!updatedBrand) {
      throw new Error("brand does not exist");
    }

    return updatedBrand;
  };

  const updateBrand = (brand) => {
    setBrand(brand);
  };

  return (
    <BrandContext.Provider
      value={{ brand, brands, updateBrand, updateBrands, setBrands }}
    >
      {props.children}
    </BrandContext.Provider>
  );
};

export default BrandContextProvider;
