import qs from "qs";
import service from "../../utils/service";

// 获取dashboard数据
export const getDashboardData = (data, vendorId) => {
  return service.get("/vendor/dashboard/" + vendorId, {
    params: data,
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};

export const getOverviewData = data => {
  return service.get("/vendor/dashboard/getOverviewData", {
    params: data,
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};

export const getOverviewReport = data => {
  return service.get("/vendor/dashboard/getOverviewReport", {
    params: data,
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};

export const getVendorFlags = data => {
  return service.get("/vendor/dashboard/getVendorFlags", {
    params: data,
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};
export const saveLink = data => {
  return service.post("/vendorLink/saveLink", data, {
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};
export const removeAmbassador = data => {
  return service.post("/vendorLink/removeAmbassador", data, {
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};
export const assignAmbassadorStatus = data => {
  return service.post("/vendorLink/assignAmbassadorStatus", data, {
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};

export const getAmbassadorsData = (vendorId, campaignId, data) => {
  return service.get(
    "/vendorLink/getAmbassadors/" + vendorId + "/" + campaignId,
    {
      params: data,
      headers: {
        token: sessionStorage.getItem("vendorToken")
      }
    }
  );
};
export const getAmbassadorDetail = data => {
  return service.get("vendorLink/getAmbassadorDetail", {
    params: data,
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};
export const getNoteCategory = () => {
  return service.get("/vendorLink/getNoteCategory", {
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};
export const getNoteList = (custId, vendorId) => {
  return service.get("/vendorLink/getCustNoteList/" + custId + "/" + vendorId, {
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};
export const saveOrUpdateNote = data => {
  return service.post("/vendorLink/saveOrUpdateNote", data, {
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};

export const deleteCustNote = data => {
  return service.post("/vendorLink/deleteCustNote", data, {
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};

export const generateVendorLink = data => {
  return service.get("/vendorLink/generateVendorLink/" + data.vendorId, {
    params: data,
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};

export const getOverviewTopSelling = data => {
  return service.get("/vendor/dashboard/getOverviewTopSelling", {
    params: data,
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};

export const saveVendorFlag = data => {
  return service.post("/vendor/dashboard/saveVendorFlag", data, {
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};

export const updateCampaignStatusRequest = data => {
  return service.post("/vendorLink/updateAmbassadorCpgnStatus", data, {
    headers: {
      token: sessionStorage.getItem("vendorToken")
    }
  });
};
