function getDateStr(value) {
    var v = parseInt(value)
    var time = new Date(v);
    var year = time.getFullYear();
    var month = time.getMonth() + 1;
    var date = time.getDate();
    month = month < 10 ? "0" + month : month;
    date = date < 10 ? "0" + date : date;
    return month + '/' + date + '/' + year
}

export default getDateStr