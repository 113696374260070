import asyncComponent from "../components/AsyncComponent";

let sharingRouter = [
  {
    path: "/sharing",
    component: asyncComponent(() => import("../views/sharing/index")),
  },
  {
    path: "/sharingDetail/:cpgnId",
    component: asyncComponent(() => import("../views/sharing/AllProducts")),
  },
];

export default sharingRouter;
