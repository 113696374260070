import asyncComponent from "../components/AsyncComponent";

let dashboard = [
    {
        path: '/dashboard1',
        component: asyncComponent(() => import("../views/dashboard/Index")),
        exact: true
    },
    {
        path: '/dashboard',
        component: asyncComponent(() => import("../views/dashboard1624")),
        exact: true
    }
]

export default dashboard