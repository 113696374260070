import asyncComponent from "../components/AsyncComponent";

let register = [
  {
    path: "/register/entrance",
    component: asyncComponent(() => import("../views/main/Main")),
    exact: true,
  },
  {
    path: "/register/sso/newage/step1",
    component: asyncComponent(() =>
      import("../views/register/newageSSOStep1/Index")
    ),
    exact: true,
  },
  {
    path: "/register/sso/newage/step2",
    component: asyncComponent(() =>
      import("../views/register/newageSSOStep2/Index")
    ),
    exact: true,
  },
  {
    path: "/register/step1",
    component: asyncComponent(() => import("../views/register/step1/Index")),
    exact: true,
  },
  {
    path: "/register/step1d",
    component: asyncComponent(() =>
      import("../views/register/step1Stage/Index")
    ),
    exact: true,
  },
  {
    path: "/register/step2",
    component: asyncComponent(() => import("../views/register/step2/Index")),
    exact: true,
  },
  {
    path: "/register/step3",
    component: asyncComponent(() => import("../views/register/step3/Index")),
    exact: true,
  },
  {
    path: "/register/step4",
    component: asyncComponent(() => import("../views/register/finish")),
    exact: true,
  },
  {
    path: "/register/step5",
    component: asyncComponent(() => import("../views/register/Step5")),
    exact: true,
  },
  {
    path: "/register/step6",
    component: asyncComponent(() => import("../views/register/step6/Index")),
    exact: true,
  },
  {
    path: "/invite/step1",
    component: asyncComponent(() =>
      import("../views/inviteFriend/step1/Index")
    ),
    exact: true,
  },
  {
    path: "/invite/step2",
    component: asyncComponent(() =>
      import("../views/inviteFriend/step2/Index")
    ),
    exact: true,
  },
];

export default register;
