import asyncComponent from "../components/AsyncComponent";

let manage = [
  {
    path: "/rewards",
    component: asyncComponent(() => import("../views/kwikCash137/Kwikcash")),
    exact: true,
  },
  {
    path: "/kwikcash",
    component: asyncComponent(() => import("../views/kwikCash137/Kwikcash")),
    exact: true,
  },
  {
    path: "/kwikcash137",
    component: asyncComponent(() => import("../views/kwickCash/Kwikcash")),
    exact: true,
  },
  {
    path: "/kwikcash/balance",
    component: asyncComponent(() =>
      import("../views/kwikCash137/KwikcashBalance")
    ),
    exact: true,
  },
  {
    path: "/kwikcash/bonus/detail/:vendorId",
    component: asyncComponent(() => import("../views/kwikCash137/activeBonus")),
    exact: true,
  },
  {
    path: "/kwikcash/bonus/redeem",
    component: asyncComponent(() =>
      import("../views/kwikCash137/redeemProducts")
    ),
    exact: true,
  },

  {
    path: "/kwikcash/bank",
    component: asyncComponent(() => import("../views/kwickCash/KwikcashBank")),
    exact: true,
  },
  {
    path: "/kwikcash/paypal",
    component: asyncComponent(() =>
      import("../views/kwickCash/KwikcashPaypal")
    ),
    exact: true,
  },
  {
    path: "/kwikcash/bank/transfer",
    component: asyncComponent(() =>
      import("../views/kwickCash/KwikcashTransfer")
    ),
    exact: true,
  },
  {
    path: "/kwikcash/verify/:pendingBankId",
    component: asyncComponent(() =>
      import("../views/kwickCash/KwikcashBankVerify")
    ),
    exact: true,
  },
  {
    path: "/kwikcash/phone",
    component: asyncComponent(() =>
      import("../views/kwickCash/KwikcashPhoneVerification")
    ),
    exact: true,
  },
];

export default manage;
