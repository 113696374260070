import qs from "qs";
import service from "../utils/service";
import sessionCache from "../utils/sessionCache";

// // 获取cpngId列表
// export const getCpgnList = (data) => {
//     return service.get("/kCash/getCpgnList", {
//         params: data,
//         headers: {
//             token: sessionCache().getItem('apiToken')
//         }
//     });
// }
// // 获取kwikCash信息
// export const getCustomerKCashInfo = (data) => {
//     return service.post("/kCash/getCustomerKCashInfo", qs.stringify(data), {
//         headers: {
//             token: sessionCache().getItem('apiToken')
//         }
//     });
// }
// 获取kwikCash银行信息
export const getBankAccountList = (data) => {
  data.portal = "WER1";
  return service.post("/kCash/getBankAccountList", qs.stringify(data), {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};
// 获取国家信息
export const getCountryInfoByCpgnId = (data) => {
  return service.post("/common/getCountryInfoByCpgnId", qs.stringify(data));
};
// 添加银行卡
export const saveBankAccount = (data) => {
  data.portal = "WER1";
  return service.post("/kCash/saveBankAccount", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};
// vendor添加银行卡
export const vendorSaveBankAccount = (data) => {
  return service.post("/vendor/cashout/saveBankAccount", data, {
    headers: {
      token: sessionCache().getItem("vendorToken"),
    },
  });
};
// 转钱
export const transKCashOut = (data) => {
  data.portal = "WER1";
  return service.post("/kCash/transKCashOut", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};
// vendor转钱
export const vendorTransKCashOut = (data) => {
  return service.post("/vendor/cashout/transKCashOut", data, {
    headers: {
      token: sessionCache().getItem("vendorToken"),
    },
  });
};
// 获取cashout历史
// export const getKCashOutHistory = (data) => {
//     return service.post("/kCash/getKCashOutHistory", qs.stringify(data), {
//         headers: {
//             token: sessionCache().getItem('apiToken')
//         }
//     });
// }

// 获取cashDetail
export const getKCashEarnHistory = (data) => {
  data.portal = "WER1";
  return service.post("/kCash/getKCashEarnHistory", qs.stringify(data), {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

// 获取kwikCash数据
export const getKcVuKcashTotal = (data) => {
  data.portal = "WER1";
  return service.get("/kCash/getKcVuKcashTotal", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};
// view history
export const getTransactionsByCustnCurr = (data) => {
  data.portal = "WER1";
  return service.get("/kCash/getTransactionsByCustnCurr", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};
// 通知进入页面
export const insertCustFlag = (data) => {
  return service.get("/common/insertCustFlag", {
    params: data,
  });
};

//获取bonus数据
export const getRewardCards = (data) => {
  return service.post("/vendor/bonus/getRewardCards", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

//获取bonus数据 新设计
export const getSumCards = (data) => {
  return service.post("/shopifyApi/loyalty/getSumCards", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

//获取LP数据
export const getUserLpDetail = (data) => {
  return service.post("/shopifyApi/loyalty/getUserLpDetail", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

// view history
export const getAllKwikcashInfo = (data) => {
  data.portal = "WER1";
  return service.get("/kCash/getAllKcashInfoAsync", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

// view history
export const getCashOutTransactions = (data) => {
  data.portal = "WER1";
  return service.post("/kCash/getCashOutTransactions", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

// 里层transaction 数据
export const getKcashTransactions = (data) => {
  data.portal = "WER1";
  return service.post("/kCash/getKcashTransactions", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

export const sendCashOutPassCode = (data) => {
  data.subCustType = 2;
  return service.post("/common/sendCashOutPassCode", qs.stringify(data));
};

export const checkEmailAndCode = (data) => {
  return service.post("/common/checkEmailAndCode", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};
// get kcash info
export const getKcashInfo = (data) => {
  data.portal = "WER1";
  return service.get("/kCash/getKcashInfo", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

// get kcash info
export const getKcashBalanceDetail = (data) => {
  data.portal = "WER1";
  return service.get("/kCash/getKcashBalanceDetail", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

export const getKcashBalance = (data) => {
  data.portal = "WER1";
  return service.get("/kCash/getKcashBalance", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

export const sendDeleteBankDdPassCode = (data) => {
  data.sysType = 2;
  return service.post("/common/sendDeleteBankDdPassCode", qs.stringify(data));
};

export const sendUpdateBankDdPassCode = (data) => {
  data.sysType = 2;
  return service.post("/common/sendUpdateBankDdPassCode", qs.stringify(data));
};

export const sendCashOutTransPassCode = (data) => {
  data.sysType = 2;
  return service.post("/common/sendCashOutTransPassCode", qs.stringify(data));
};

export const sendCreateBankDdPassCode = (data) => {
  data.sysType = 2;
  return service.post("/common/sendCreateBankDdPassCode", qs.stringify(data));
};

export const deleteBankAccount = (data) => {
  data.portal = "WER1";
  return service.post("/kCash/deleteBankAccount", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

export const getBankAccount = (data) => {
  data.portal = "WER1";
  return service.post("/kCash/getBankAccount", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

// 添加银行卡
export const updateBankAccount = (data) => {
  data.portal = "WER1";
  return service.post("/kCash/updateBankAccount", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

export const exportTransactionsExcel = (data) => {
  data.portal = "WER1";
  return service.get("/kCash/exportTransactionsExcel", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

//获取bonus detail
export const getRewardDetail = (data) => {
  return service.post("/vendor/bonus/getRewardDetail", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};
//获取bonus active detail
export const getRewardActiveBonus = (data) => {
  return service.post("/vendor/bonus/getRewardActiveBonus", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

//获取LP w2e 产品列表
export const getW2eProductIdList = (data) => {
  return service.post("/shopifyApi/loyalty/getW2eProductIdList", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

//获取LP w2r 产品列表
export const getW2rProductIdList = (data) => {
  return service.post("/shopifyApi/loyalty/getW2rProductIdList", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

//点击Redeem
export const checkRedeemPts = (data) => {
  return service.post("/shopifyApi/loyalty/checkRedeemPts", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

//rewards页面获取points
export const getBalancePoints = (data) => {
  data.portal = "WER1";
  return service.get(
    "/shopifyApi/loyalty/loyalty-point/customer-balance-cust",
    {
      params: data,
      headers: {
        token: sessionCache().getItem("apiToken"),
      },
    }
  );
};

//rewards页面获取redeem配置
export const getEarnRedeemSettings = (data) => {
  data.portal = "WER1";
  return service.get("/shopifyApi/loyalty/loyalty-point/get-earn-redeem-cust", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

//获取coupon数据
export const getLpCoupons = (data) => {
  data.portal = "WER1";
  return service.get("/shopifyApi/get-lp-coupons", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

//free shipping 点击redeem
export const clickFSRedeem = (data) => {
  return service.post("/shopifyApi/free-shipping", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

//discount account 点击redeem
export const clickDARedeem = (data) => {
  return service.post("/shopifyApi/discount-absolute", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

//discount percent 点击redeem
export const clickDPRedeem = (data) => {
  return service.post("/shopifyApi/discount-relative", data, {
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};
