import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import utils from "../utils";

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null,
      };
    }
    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component: component,
      });
    }
    render() {
      const C = this.state.component;

      return C ? <C {...this.props} utils={utils} /> : <div></div>;
    }
  }

  return withTranslation()(AsyncComponent);
}
