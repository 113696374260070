import getDateStr from '../../utils/getDateStr'

const initialState = {
    orderList: {}
}

const productList = (state = initialState, action) => {
    let orderList;
    switch (action.type) {
        case 'GET_ORDER_HISTORY_LIST':
            orderList = action.orderList;
            let resOrderList = {};
            for(let i = 0 ; i < orderList.length ; i++){
                let oKey = getDateStr(orderList[i].entryDate);
                if(!resOrderList[oKey]){
                    resOrderList[oKey] = [];
                }
                resOrderList[oKey].push(orderList[i]);
            }
            return {
                ...state,
                orderList: resOrderList
            }
        case 'UPDATE_ORDER_STATE':
            let { data, index, orderStates, orderStatesDesc } = action;
            orderList = Object.assign({}, state.orderList);
            orderList[data][index].orderStatus = orderStates;
            orderList[data][index].orderStatusDesc = orderStatesDesc;
            return {
                ...state,
                orderList
            }
        default:
            return state
    }
}

export default productList