import React, {useState} from 'react';
import './style.scss';
import {Input} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
function SearchFilter({handleSearch, searchValue, setSearchValue, showFiltersArea, setShowFiltersArea}) {

    const onkeydown = (e) =>{
        if (e.keyCode === 13) {
            handleSearch(searchValue);
        }
    }
    return(
        <div className="ambassadors-search-filters">
            <div className="ambassadors-search-area">
                <Input  onChange={(e) => setSearchValue(e.target.value)}
                        onKeyDown={(e)=>{onkeydown(e)}}
                        size="large" placeholder="Search Ambassador"
                        prefix={<SearchOutlined onClick={()=>{handleSearch(searchValue)}}/>}
                        value={searchValue}/>
            </div>
            {/*<a onClick={()=>setShowFiltersArea(!showFiltersArea)}>*/}
            {/*    <div className="ambassadors-filters">*/}
            {/*        <span>Filters</span>*/}
            {/*        <img src={require("../../../../images/share-select-downarrow.png")} alt="" />*/}
            {/*    </div>*/}
            {/*</a>*/}
        </div>
    )
}

export default SearchFilter;