const initialState = {
    dataList: [],
    currentCountry: ''
}

const productList = (state = initialState, action) => {
    let currentCountry = action.currentCountry;
    switch (action.type) {
        case 'SET_PRODUCT_LIST':
            let { dataList } = action;
            return Object.assign({}, state, {
                dataList,
                currentCountry
            })
        case 'TOGGLE_CLASS_SHOW':
            let { country, vendorIndex } = action;
            let _dataList = Object.assign([], state.dataList);
            _dataList[country][vendorIndex].show = !!_dataList[country][vendorIndex].show;
            _dataList[country][vendorIndex].show = !_dataList[country][vendorIndex].show;
            return Object.assign({}, state, {
                dataList: _dataList
            });
        case 'PRODUCT/LIST/UPDATE_CURRENT_COUNTRY':
            return Object.assign({}, state, {
                currentCountry
            });
        default:
            return state
    }
}

export default productList