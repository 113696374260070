import asyncComponent from "../components/AsyncComponent";

let buyRouter = [
  {
    path: "/buy",
    component: asyncComponent(() => import("../views/sharing/index")),
  }
];

export default buyRouter;
