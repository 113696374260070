const getLanguage = () => {
  // let campaignId = sessionStorage.getItem('campaignId');
  let language = sessionStorage.getItem('language');
  // if (language) {
  //   return language;
  // } else {
  //   if (campaignId === '1') {
  //     sessionStorage.setItem('language', 'ja_JP');
  //     return 'ja_JP';
  //   } else if (campaignId === '2') {
  //     sessionStorage.setItem('language', 'en_US');
  //     return 'en_US';
  //   } else {
  //     return 'en_US';
  //   }
  // }
  return language ?.toLowerCase() || 'en_us'
}

export default getLanguage;