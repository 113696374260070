export default {
  translation: {
    CHECK_EMAIL_TITLE: "Eメールをチェック",
    WELCOME_BACK: "ようこそ！",
    ENTER_YOUR_CREDENTIALS: "Enter your credentials to continue",
    EMAIL_FIELD: "Eメール",
    CHECK_BUTTON: "確認",
    EMAIL_IS_REQUIRED: "Eメールが必要です",
    CREATE_PASSWORD_TITLE: "パスワード作成",
    SUBMIT_BUTTON: "送信",
    LOGIN_TITLE: "ログイン",
    PASSWORD_FIELD: "パスワード",
    PLEASE_INPUT_YOUR_PASSWORD: "パスワードを入力してください",
    FORGOT_PASSWORD: "パスワードを忘れた場合",
    REMEMBER_ME: "ログイン情報を保存",
    LOGIN_TO_ACCOUNT_BUTTON: "アカウントへログイン",
    SIDEBAR_ORDER_HISTORY: "注文履歴",
    SIDEBAR_SIGNOUT: "ログアウト",
    FOOTER_MENU_HOME: "ホーム",
    FOOTER_MENU_SHARE: "共有",
    FOOTER_MENU_KWIKCASH: "KwikCash",
    FOOTER_MENU_HISTORY: "履歴",
    SHARE_THE_PRODUCTS_TITLE: "商品を共有",
    MY_CAMPAIGNS: "NewAgeキャンペーン",
    PLEASE_SELECT_A_CAMPAIGN_TO_SHARE:
      "Select the products you would like to create a link for.",
    CREATE_A_KWIKLINK_BUTTON: "Create a Kwiklink",
    MANAGE_MY_KWIKLINKS: "Manage my Kwiklinks",
    KWIK_CASH_TITLE: "1Cash",
    KWIK_LINK_TITLE: "Share the Products",
    FIELD_PRODUCT: "Product",
    FIELD_QUANTITY: "Quantity",
    FIELD_PRICE: "Price",
    FIELD_KWIKCASH: "Kwikcash",
    SHARE_THIS_PRODUCT_TO: "Share",
    COPY_LINK: "Copy Link",
    RETURN_TO_HOME_BUTTON: "Return to Home",
    COPY_SUCCESS: "Link Successfully Copied",
    TOTAL_KWIKCASH_EARNED: "Total Kwikcash Earned",
    TOTAL_CASHED_OUT: "Total Cashed Out",
    KWIKCASH_BALANCE: "1Cash Balance",
    CASH_OUT_BUTTON: "Cash Out",
    WEEK_COL_NAME: "Week",
    KWIK_CASH_COL_NAME: "Kwik Cash",
    PRODUCT_INFO_TITLE: "Product Info",
    KWIKCASH_VALUE: "KwikCash Value",
    DESCRIPTION: "Description",
    IMPORTANT_INFORMATION: "Important Information",
    INGREDIENTS: "Ingredients",
    USAGE: "Usage",
    HANDLING_INSTRUCTIONS: "Handling Instructions",
    MANUFACTURER: "Manufacturer",
    ADD_TO_CART_BUTTON: "Add to Cart",
    YOUR_CART_TITLE: "Your Cart",
    PRODUCTS: "Products",
    REMOVE_LINK: "Remove",
    FIELD_TAX: "Tax",
    FIELD_TOTAL: "Total",
    PROCEED_TO_CHECKOUT_BUTTON: "Proceed to Checkout",
    CHECKOUT_TITLE: "Checkout",
    SHIPPING_ADDRESS: "Shipping Address",
    NEW_LINK: "New",
    PAYMENT_BREAKDOWN: "Payment Breakdown",
    SHOW_SAVE_CARDS: "Show Save The Cards",
    FIELD_NAME_ON_CARD: "Name on Card",
    FIELD_CARD_NUMBER: "Card Number",
    FIELD_EXPIRY_YEAR: "Expiry Year",
    FIELD_EXPIRY_MONTH: "Expiry Month",
    FIELD_CVV: "CVV",
    SAVE_THIS_CARD: "Save This Card",
    BILLING_ADDRESS_IS_SAME_SHIPPING_ADDRESS:
      "Billing Address is Same As Shipping Address",
    BILLING_ADDRESS: "Billing address",
    FIELD_FIRST_NAME: "First Name",
    FIELD_LAST_NAME: "Last Name",
    FIELD_ADDRESS_LINE: "Address Line",
    FIELD_ZIP: "Zip",
    FIELD_PHONE: "Phone Number",
    FIELD_CITY: "City",
    FIELD_STATE: "State",
    FIELD_COUNTRY: "Country",
    FIELD_NAME: "Name",
    PLEASE_SELECT: "select",
    SHIPPING: "Shipping",
    CONTINUE_TO_PLACE_ORDER_BUTTON: "Continue to Place Order",
    SAVE_AND_USE_THIS_ADDRESS_BUTTON: "Proceed to Next",
    CONGRATULATIONS: "Order Complete!",
    PAYMENT_SUCCESSFUL: "Payment Successful",
    STANDARD: "Standard",
    ORDER_ACCEPTED: "Order Accepted",
    CHECKOUT_MESSAGE1:
      "Your Order No. #{{orderId}} has been placed and an email confirmation has been sent to",
    CHECKOUT_MESSAGE2:
      "Congrats! Earn $2.5 every time someone buys from your promo code! Click the Share button below.",
    SHARE_THE_PRODUCT_BUTTON: "Share Your Link",
    TRACK_ORDER: "Track Order",
    WAVES_COL: "Waves",
    NAME_COL: "Name",
    ORDER_K_CASH_COL: "Order Total",
    K_CASH_RATE: "K.Cash Rate",
    CASH_HISTORY_TITLE: "Cash History",
    TRANSFER_TO_BANK_TITLE: "Transfer to Bank",
    SAVED_BANK_LINK: "Saved Bank",
    ADD_BANK_LINK: "Add Bank",
    SELECT_LINK: "Select",
    PROCEED_TO_NEXT_BUTTON: "Proceed to Next",
    FIELD_BANKS_COUNTRY: 'Bank"s Country',
    FIELD_CURRENCY_CODE: "Currency Code",
    FIELD_NAME_ON_ACCOUNT: "Name on Account",
    FIELD_NAME_ON_ROUTING_NUMBER: "Routing Number",
    FIELD_ACCOUNT_NUMBER: "Account Number",
    FIELD_ACCOUNT_TYPE: "Account Type",
    FIELD_ACCOUNT_TYPE_OPTION_CHECKING: "Checking",
    FIELD_ACCOUNT_TYPE_OPTION_SAVINGS: "Savings",
    FIELD_SWIFT_CODE_BIC: "Swift Code/BIC",
    FIELD_BANK_NAME: "Bank Name",
    FIELD_BANK_NUMBER: "Bank Number",
    FIELD_BRANCH_NAME: "Branch Name",
    FIELD_BRANCH_CODE: "Branch Code",
    SAFE_KEEP_MESSAGE:
      "This information will be securely saved as per the Terms of Service & Privacy Policy",
    SAVE_BANK_DETAILS_BUTTON: "Save Bank Details",
    AVAILABLE_BALANCE: "Available Balance",
    TRANSFER_TO_INTRODUCE_MESSAGE:
      "Choose the amount of money you would like to transfer from your kwikcash to your selected bank account.",
    FIELD_AMOUNT: "Amount",
    SELECTED_BANK: "Selected Bank",
    VERIFY_BANK_CARD_INFORMATION:
      "Please Verify that the Bank and the Kwikcash Amount are correct. You CANNOT change your mind after you do this.",
    TRANSACTION_COMPLETE: "Transaction Complete!",
    TRANSACTION_CONSEQUENCE_MESSAGE:
      "Your Transaction number is {{amount}} and it has been placed. An email confirmation has been sent to {{email}}",
    TRANSACTION_CONSEQUENCE_MESSAGE2:
      "Awesome! Please allow up to 24 Hours for the 1Cash Balance to get updated.",
    RETURN_BUTTON: "Return To Home",
    VIEW_TRANSACTION_HISTORY: "View Transaction history",
    ORDER_TEXT: "Order",
    ITEM_LIST_IS_EMPTY: "Please select product",
    CART_MUST_NOT_BE_EMPTY: "The shopping cart must not be empty",
    CLICK_SEND_EMAIL:
      "Please click the button to send an email to continue the initial password",
    SELECTED_DATE_GREATER_CURRENT_DATE:
      "The selected date should be greater than the current date",
    CARD_NUMBER_FORMAT_IS_INCORRECT: "The card number format is incorrect",
    ITEM_ADDED_TO_CART: "You have successfully added item to cart!",
    ORDER_NUMBER: "Order Number",
    CREATION_TIME: "Order Date",
    ORDER_STATUS: "Order status",
    ARE_YOU_SURE: "Are you sure?",
    CANCEL: "Cancel",
    OK: "Ok",
    EMAIL_IS_NOT_UNIQUE:
      "This email is already used in kwikclick.com. Please use another email, or go to kwikclick.com to log in to place orders on these or more products.",
    EMAIL_IS_NOT_UNIQUE_ARIIX:
      "Our records show that this email has already been registered as a customer of ARIIX Japan. Please use another email, or go to kwikclick.com to log in with this email to explore more campaigns.",
    REQUIRED_FIELD: "Required field",
    INCORRECT_EMAIL_FORMAT: "Incorrect email format",
    REFUND: "Refund",
    DELETE: "Delete",
    PLEASE_INPUT_TAGLINK: "Please input Tag Link",
    PLEASE_INPUT_CITY: "Please input city",
    UPDATE_SUCCESSFULLY: "Updated Successfully",
    TAGLINK_TEXT: "Tag Link",
    EXPLORATION_TITLE: "Exploration",
    SEE_ALL_NUM_PRODUCTS: "See All {{prodNumber}} Products",
    LEARN_MORE_LINK: "Learn More",
    KWIKCASH_VALUE_MESS: "Kwikcash Value",
    SHARE_YOUR_LINK: "SHARE YOUR LINK",
    SEND_LINK_AND_EARN_KWIKCASH_MESS: "Send Link & Earn Kwikcash",
    PRICE_TEXT: "PRICE",
    OUT_OF_STOCK_TEXT: "Out of Stock",
    ORDER_HISTORY_TEXT: "Order History",
    ORDER_HISTORY_TIPS_NEED_PURCHASE: "You need to make a purchase first!",
    ORDER_HISTORY_EXPLORE_PRODUCTS: "Explore Products",
    ORDER_HISTORY_ORDER_ON: "Order On",
    ORDER_DETAIL_EXPECTED_DATE: "Expected Date",
    ORDER_DETAIL_TRACKING_ID: "Tracking ID",
    ORDER_DETAIL_BILLING_INFO_SAME_AS_SHIPPING: "Billing Info Same as Shipping",
    ORDER_DETAIL_ORIGINAL_PRICE: "Original Price",
    ORDER_DETAIL_SHIPPINGN_HANDING: "Shipping and Handling",
    KWIK_CASH_AVAILABLE_BALANCE: "Available Balance",
    KWIK_CASH_PENDING_BALANCE: "Pending Balance",
    KWIK_CASH_VIEW_HISTORY: "View History",
    KWIK_CASH_TRANSFER_BANK: "Transfer to Bank",
    ACCOUNT_NUMBER_4: "Account number should be no less than 4 characters",
    KWIK_CASH_PENDING: "pending",
    KWIK_CASH_PAID: "paid",
    KWIK_CASH_FULFILLED: "fulfilled",
    CAMPAIGNS_SHARE_A_CAMPAIGN: "Share a Campaign",
    CAMPAIGNS_ACTIVE_CAMPAIGN: "Active Campaign",
    CAMPAIGNS_NODATA_TEXT: "To get started, find a product to endorse",
    CAMPAIGNS_EXPLORE_PRODUCTS: "Explore Products",
    PROGRESS_LOADING: "Loading...",
    LOGIN_WELCOME_BACK: "Welcome Back!",
    LOGIN_CREDENTIALS: "Enter your credentials to continue",
    LOGIN_LABEL_USERNAME: "Username",
    LOGIN_PLACEHOLDER_USERNAME: "Username or Email",
    LOGIN_REQUIRED_USERNAME: "Username is required",
    LOGIN__LABEL_PASSWORD: "Password",
    LOGIN_PLACEHOLDER_PASSWORD: "Password",
    LOGIN_REQUIRED_PASSWORD: "Please input your password!",
    LOGIN_REMEMBER_ME: "Remember me",
    LOGIN_FORGOT_PASSWORD: "Forgot Password?",
    LOGIN_BUTTON_LOGIN: "Login",
    LOGIN_NEED_ACCOUNT: "Need an account?",
    LOGIN_CREATE_ACCOUNT: "Create New Account",
    VIEWS_REGISTER_1_CREATE_ACCOUNT: "Create Your Account ",
    VIEWS_REGISTER_1_FIELDS_REQUIRED:
      "Please fill out the form below. All fields are required.",
    VIEWS_REGISTER_1_LABEL_EMAIL_ADDRESS: "Email Address",
    VIEWS_REGISTER_1_PLACEHOLDER_EMAIL_ADDRESS: "Email Address",
    VIEWS_REGISTER_1_REQUIRED_EMAIL: "Email Address must not be empty",
    VIEWS_REGISTER_1_LABEL_FIRST_NAME: "First Name",
    VIEWS_REGISTER_1_PLACEHOLDER_FIRST_NAME: "First Name",
    VIEWS_REGISTER_1_REQUIRED_FIRST_NAME: "First Name must not be empty",
    VIEWS_REGISTER_1_LABEL_LAST_NAME: "Last Name",
    VIEWS_REGISTER_1_PLACEHOLDER_LAST_NAME: "Last Name",
    VIEWS_REGISTER_1_REQUIRED_LAST_NAME: "Last Name must not be empty",
    VIEWS_REGISTER_1_LABEL_USER_NAME: "Username",
    VIEWS_REGISTER_1_PLACEHOLDER_USER_NAME: "Username",
    VIEWS_REGISTER_1_REQUIRED_USER_NAME: "User Name must not be empty",
    VIEWS_REGISTER_1_LABEL_PHONE: "Phone Number",
    VIEWS_REGISTER_1_PLACEHOLDER_PHONE: "Phone Number",
    VIEWS_REGISTER_1_REQUIRED_PHONE: "Phone Number must not be empty",
    VIEWS_REGISTER_1_BUTTON_NEXT: "Next",
    VIEWS_REGISTER_1_TIPS_DIGIT_SENT:
      "A 4-digit code has been sent to your email",
    VIEWS_REGISTER_1_TIPS_EMAIL_REGISTERED:
      "Our records show that this email has already been registered with WER1.",
    VIEWS_REGISTER_1_BUTTON_EMAIL_REGISTERED_FORGOT_PASSWORD: "Forgot Password",
    VIEWS_REGISTER_1_BUTTON_EMAIL_REGISTERED_CANCEL: "Cancel",
    VIEWS_REGISTER_1_TIPS_USERNAME_REGISTERED:
      "This username is taken. Please try another one. ",
    VIEWS_REGISTER_1_BUTTON_OK: "Ok",
    VIEWS_REGISTER_2_VERIFICATION: "Verification",
    VIEWS_REGISTER_2_TO_VERIFY: "To verify that it is you",
    VIEWS_REGISTER_2_TIPS_ENTER:
      "Enter the 4 digit code that was sent to you at",
    VIEWS_REGISTER_2_BUTTON_NEXT: "Next",
    VIEWS_REGISTER_2_NEED_CODE: "Don’t have a code yet?",
    VIEWS_REGISTER_2_LINK_RESEND: "Resend",
    VIEWS_REGISTER_2_LOADING: "Loading...",
    VIEWS_REGISTER_3_TITLE_PASSWORD_CREATE: "Create New Password",
    VIEWS_REGISTER_3_TITLE_ENTER_NEW_PASSWORD: "Enter a new secure password",
    VIEWS_REGISTER_3_CREATE_LABEL: "Create Password",
    VIEWS_REGISTER_3_CREATE_PLACEHOLDER: "Create Password",
    VIEWS_REGISTER_3_CREATE_REQUIRED: "Please input your password!",
    VIEWS_REGISTER_3_CONFIRM_LABEL: "Confirm Password",
    VIEWS_REGISTER_3_CONFIRM_PLACEHOLDER: "Confirm Password",
    VIEWS_REGISTER_3_CONFIRM_REQUIRED: "Please input your password!",
    VIEWS_REGISTER_3_TOAST_PATTERN:
      "The password requires eight characters mininum with at least one upper case, one lower case and a numeric number.",
    VIEWS_REGISTER_3_TIPS_MATCH: 'The two passwords don"t match',
    VIEWS_REGISTER_3_BUTTON_NEXT: "Next",
    VIEWS_REGISTER_3_BUTTON_BACK: "Back",
    VIEWS_REGISTER_3_LOADING: "Loading...",
    VIEWS_REGISTER_4_TITLE: "Upload a profile picture",
    VIEWS_REGISTER_4_MESSAGE:
      "This will make you famous and add trust on your account.",
    VIEWS_REGISTER_4_BUTTON_NEXT: "Proceed to Next",
    VIEWS_REGISTER_4_TIPS_NOT_NOW: "Not Now?",
    VIEWS_REGISTER_4_LINK_DO_LATER: "Wanna do it later",
    VIEWS_REGISTER_5_TITLE: "Create your account",
    VIEWS_REGISTER_5_MESSAGE: "Please provide the following info below",
    VIEWS_REGISTER_5_TIPS_YOUR_BIO: "Your Bio",
    VIEWS_REGISTER_5_PLACEHOLDER_TYPING: "Start typing here...",
    VIEWS_REGISTER_5_BUTTON_NEXT: "Submit & Proceed to Next",
    VIEWS_REGISTER_5_TIPS_NOT_NOW: "Not Now?",
    VIEWS_REGISTER_5_LINK_DO_LATER: "Wanna do it later",
    VIEWS_REGISTER_6_BUTTON_BACK: "Back",
    VIEWS_REGISTER_6_TITLE: "Provide your personal info",
    VIEWS_REGISTER_6_MESSAGE: "Please provide some of your info.",
    VIEWS_REGISTER_6_ADDRESS_LINE_1: "Address Line 1",
    VIEWS_REGISTER_6_ADDRESS_LINE_2: "Address Line 2",
    VIEWS_REGISTER_6_POSTAL_CODE: "Postal Code",
    VIEWS_REGISTER_6_BUTTON_NEXT: "Next",
    VIEWS_REGISTER_6_COUNTRY_USA: "United States",
    VIEWS_REGISTER_6_COUNTRY_CHA: "China",
    VIEWS_REGISTER_SSO_1_TIPS_EMAIL_REGISTERED:
      "Our records show that this User Name has already been registered with Kwikclick. ",
    VIEWS_REGISTER_SSO_1_BUTTON_OK: "Ok",
    VIEWS_REGISTER_SSO_1_TIPS_SIGNUP: "Signup",
    VIEWS_REGISTER_SSO_1_TITLE: "Create your account",
    VIEWS_REGISTER_SSO_1_MESSAGE:
      "Congratulation! Your NewAge ID and Password have been verified. Please provide the following info below",
    VIEWS_REGISTER_SSO_1_TIPS_EMAIL:
      "Email  (We will verify the uniqueness of your email)",
    VIEWS_REGISTER_SSO_1_TIPS_FIRST: "First Name",
    VIEWS_REGISTER_SSO_1_REQUIRED_FIRST: "First Name must not be empty",
    VIEWS_REGISTER_SSO_1_TIPS_LAST: "Last Name",
    VIEWS_REGISTER_SSO_1_REQUIRED_LAST: "Last Name must not be empty",
    VIEWS_REGISTER_SSO_1_TIPS_USERNAME:
      "User Name  (We will verify the uniqueness of your username)",
    VIEWS_REGISTER_SSO_1_REQUIRED_USERNAME: "User Name must not be empty",
    VIEWS_REGISTER_SSO_1_TIPS_PHONE: "Phone Number",
    VIEWS_REGISTER_SSO_1_REQUIRED_PHONE: "Phone Number must not be empty",
    VIEWS_REGISTER_SSO_1_BUTTON_NEXT: "Next",
    CAMPAIGNS_BUTTON_OK: "Ok",
    CAMPAIGNS_LOADING: "Loading...",
    CAMPAIGNS_CANNOT_SHARE_MESSAGE_1: 'Sorry, you can"t share product ',
    CAMPAIGNS_CANNOT_SHARE_MESSAGE_2:
      ". Please buy this product now, or apply for the eligibility.",
    CAMPAIGNS_TITLE_STATS: "Stats",
    CAMPAIGNS_TIPS_CASH_EARNED: "Total Kwik Cash Earned This Week",
    CAMPAIGNS_TIPS_TOTAL_KWIKCASH_EARNED: "Total Kwikcash Earned",
    CAMPAIGNS_TIPS_GENERATE_SALES: "Generated Sales",
    CAMPAIGNS_TITLE_CAMPAIGNS: "Campaigns",
    CAMPAIGNS_TIPS_VIEW_DETAIL: " (Click to view details)",
    CAMPAIGNS_TITLE_ALL_STATS: "All Campaign Stats",
    CAMPAIGNS_TIPS_KWIK_INFO: "Kwik Info",
    CAMPAIGNS_TITLE_TOTAL_BUYERS: "Total Buyers",
    CAMPAIGNS_BUTTON_QR_CODE: "QR code",
    CAMPAIGNS_BUTTON_COPY_LINK: "Copy Link",
    CAMPAIGNS_HEADER_TITLE_CAMPAIGNS_STATS: "Campaigns Stats",
    CAMPAIGNS_TITLE_TOTAL_CUSTOMERS: "Total Customers",
    CAMPAIGNS_BUTTON_VIEW_DATA: "View Data",
    CAMPAIGNS_TITLE_ACTIVE_CAMPAIGN: "Active Campaign",
    CAMPAIGNS_TITLE_SHARE_CAMPAIGN: "Share a Campaign",
    CAMPAIGNS_TITLE_CAMPAIGN_DETAILS: "Campaign Details",
    CAMPAIGNS_TITLE_WAVES: "Waves",
    CAMPAIGNS_TIPS_KWIKCASH_EARNED: "1Cash Earned",
    CAMPAIGNS_TITLE_BUYER: "Buyer",
    CAMPAIGNS_COLOR_MEANS_GREEN: "Green means KwikCash is avaliable",
    CAMPAIGNS_COLOR_MEANS_YELLOW: "Yellow means KwikCash is pending",
    CAMPAIGNS_COLOR_MEANS_RED: "Red means KwikCash is not deliverable",
    CAMPAIGNS_TITLE_KWIK_INFO: "Kwik Info",
    SHARE_LINK_IMAGE_5M: "Images must not be larger than 5 megabytes",
    SHARE_LINK_REFERRED_BY: "Referred By",
    SHARE_LINK_DESCRIPTION: "Description",
    SHARE_LINK_ADD_TO_CART: "+ Add To Cart",
    SHARE_LINK_PRODUCTS_DESC: "Product Description",
    SHARE_LINK_LEAVE_MESSAGE: "Leave a review",
    SHARE_LINK_LEARN_MORE: "Learn More",
    SHARE_LINK_REVIEWS: "Reviews",
    SHARE_LINK_RATE_THE_PRODUCT: "Rate the Product",
    SHARE_LINK_SHARE_YOUR_THOUGHTS_ON_THE_PRODUCT:
      "Share your thoughts on the product",
    SHARE_LINK_ADD_AN_IMAGE_TO_YOUR_REVIEW: "Add an image to your review",
    SHARE_LINK_DRAG_AND_DROP: "DRAG AND DROP",
    SHARE_LINK_YOUR_FILES_TO_ASSETS_OR_BROWSE:
      "your files to assets,or <span>browse</span>",
    SHARE_LINK_LEAVE_REVIEW: "Leave Review",
    SHARE_LINK_MY_CART: "My Cart",
    SHARE_LINK_CHECKOUT: "CHECKOUT",
    SHARE_LINK_CHECKOUT2: "Checkout",
    SHARE_LINK_DISCOUNT: "Discount",
    SHARE_LINK_WILL_BE_CALCULATED_AT_CHECKOUT: "Will be calculated at checkout",
    SHARE_LINK_RETURNING_CUSTOMER: "Returning Customer",
    SHARE_LINK_SIGN_IN_FOR_FASTER_CHECKOUT: "Sign in for faster checkout",
    SHARE_LINK_USERNAME_OR_EMAIL: "Username or Email",
    SHARE_LINK_USERNAME_IS_REQUIRED: "Username is required",
    SHARE_LINK_PASSWORD: "Password",
    SHARE_LINK_OR: "OR",
    SHARE_LINK_NEW_CUSTOMER: "New Customer",
    SHARE_LINK_DONT_HAVE_A_MESSAGE:
      "Dont have a kwik account yet? This is the first step! Complete the transaction to begin setting up your account!",
    SHARE_LINK_ADD_NEW_ADDRESS: "Add New Address",
    SHARE_LINK_TO_MAKE_SURE_MESSAGE:
      "To make sure the connection works property, please make sure this is the email that you used or will use on your Kwik Account.",
    SHARE_LINK_ADDRESS: "Address",
    SHARE_LINK_APARTMENT_SUITE_ETC: "Apartment, suite, etc. (optional)",
    SHARE_LINK_APT_SUITE_ETC: "Apt, suite, etc. (optional)",
    SHARE_LINK_CITY: "City",
    SHARE_LINK_COUNTRY: "Country",
    SHARE_LINK_STATE: "State",
    SHARE_LINK_ZIP_CODE: "Zip Code",
    SHARE_LINK_PHONE: "Phone",
    SHARE_LINK_CANCEL: "Cancel",
    SHARE_LINK_PRODUCTS: "Products",
    SHARE_LINK_SHIPPING: "Shipping",
    SHARE_LINK_TOTAL: "Total",
    SHARE_LINK_ENTER_YOUR_CARD_DETAILS: "Enter Your Card Details",
    SHARE_LINK_PLEASE_INPUT_YOUR_CARD_NAME: "Please input your card name!",
    SHARE_LINK_PLEASE_INPUT_YOUR_CARD_NUMBER: "Please input your card number!",
    SHARE_LINK_EXPIRATION_DATE: "Expiration Date",
    SHARE_LINK_EXPIRATION_YEAR: "Expiration Year",
    SHARE_LINK_PLEASE_INPUT_YOUR_CARD_CVV: "Please input your card CVV!",
    SHARE_LINK_BILLING_ADDRESS_IS_THE_SAME_MESSAGE:
      "Billing address is the same as delivery address",
    SHARE_LINK_ENTER_YOUR_BILLING_ADDRESS: "Enter Your Billing address",
    SHARE_LINK_SAVE_THIS_CARD: "Save This Card",
    SHARE_LINK_COUNTINUE: "Continue",
    SHARE_LINK_SHIPPING_ADDRESS: "Shipping Address",
    SHARE_LINK_EDIT: "Edit",
    SHARE_LINK_SHIPPING_AND_HANDLING: "Shipping and Handling",
    SHARE_LINK_CHANGE: "Change",
    SHARE_LINK_PRICE_COUNT_ITEM: "Price ({{count}} item)",
    SHARE_LINK_PRICE_TAX: "Tax",
    SHARE_LINK_PRICE: "PRICE",
    SHARE_LINK_PLACE_YOUR_ORDER: "Place Your Order",
    SHARE_LINK_PAYMENT_SUCCESSFUL: "Payment Successful!",
    SHARE_LINK_CONGRATS_MESSAGE:
      "Congrats! Earn Kwik cash every time someone buys from your promo code! Click the Share button below.",
    SHARE_LINK_SHARE_YOUR_LINK: "SHARE YOUR LINK",
    SHARE_LINK_CREATE_MY_ACCOUNT: "Create My Account",
    SHARE_LINK_DOWNLOAD_IMAGE: "download image",
    SHARE_LINK_COPY_LINK: "Copy Link",
    SHARE_LINK_ORDER_ACCEPTED: "Order Accepted",
    SHARE_LINK_SELECT_THE_PRODUCTS_YOU_WOULD_LIKE:
      "Select the products you would like",
    SHARE_LINK_OUT_OF_STOCK: "Out of Stock",
    SHARE_LINK_PRICE2: "Price",
    SHARE_LINK_ADD: "Add",
    SHARE_LINK_CONTINUE_TO_CHECKOUT: "Continue to Checkout",
    SHARE_LINK_SO_YOURE_ALREADY_MESSAGE:
      "So you’re already in {{totalAlreadyJoin}} of the {{totalCpgn}} campaigns you selected. When you complete the purchase of the products you will be joining {{referName}} in the {{totalCanJoin}} campaigns selected",
    SHARE_LINK_SINCE_YOU_ALREADY_MESSAGE:
      "Since you’ve already joined this campaign with {{originalName}}, they will get the credit.",
    SHARE_LINK_SINCE_YOU_HAVE_ALREADY_MESSAGE:
      "Since you’ve have already joined all {{totalAlreadyJoin}} campaigns. When you complete the purchase of the products , they will get the credit.",
    SHARE_LINK_CLICK_TO_SEE_DETAILS: "Click to see details",
    SHARE_LINK_REFERRER: "Referrer",
    SHARE_LINK_CAMPAIGN: "Campaign",
    SHARE_LINK_CAMPAIGNS_TYPE: "Campaign Type",
    SHARE_LINK_DATE_JOINED: "Date Joined",
    SHARE_LINK_SINGLE_CAMPAIGN: "Single Campaign",
    SHARE_LINK_ULTI_PRODUCT: "ulti - Product",
    SHARE_LINK_KWIKCASH: "1Cash",
    SHARE_LINK_QUANTITY: "Quantity",
    SHARE_LINK_SUBTOTAL: "SubTotal",
    SHARE_LINK_PLEASE_INPUT_YOUR_FIRSTNAME: "Please input your firstName!",
    SHARE_LINK_PLEASE_INPUT_YOUR_LASTNAME: "Please input your lastName!",
    SHARE_LINK_PLEASE_INPUT_YOUR_ZIP: "Please input your zip!",
    SHARE_LINK_PLEASE_INPUT_YOUR_STATE: "Please input your state!",
    SHARE_LINK_PLEASE_INPUT_YOUR_CITY: "Please input your city!",
    SHARE_LINK_PLEASE_INPUT_YOUR_ADDRESS_LINE1:
      "Please input your Address Line1!",
    SHARE_LINK_PLEASE_SELECT_YOUR_STATE: "Please select your state!",
    SHARE_LINK_PLEASE_INPUT_YOUR_ADDRESS_PHONE:
      "Please input your Address phone!",
    SHARE_LINK_EXISTING_KWIK_USER_LOGIN_HERE: "Existing Kwik User? Login Here",
    SHARE_LINK_STEP_ADDRESS: "Address",
    SHARE_LINK_STEP_PAYMENTS: "Payments",
    SHARE_LINK_STEP_SUMMARY: "Summary",
    SHARE_LINK_BUTTON_LOGIN: "Login",
    SHARE_LINK_BUTTON_CONTINUE: "Continue",
    SHOPPING_NEW_AGE_PV: "NewAge PV",
    SHOPPING_PRODUCT_DESCRIPTION: "Product Description",
    SHOPPING_REVIEWS: "Reviews",
    SHOPPING_LEAVE_REVIEW: "Leave a review",
    SHOPPING_RATE_PRODUCT: "Rate the Product",
    SHOPPING_SHARE_THOUGHTS_PRODUCT: "Share your thoughts on the product",
    SHOPPING_PLACEHOLDER_TYPING: "Start typing",
    SHOPPING_PLEASE_TYPING: "Please input Start typing",
    SHOPPING_TIPS_ADD_IMAGE: "Add an image to your review",
    SHOPPING_BUTTON_REMOVE: "Remove",
    SHOPPING_TIPS_IMAGE_UPLOAD_1: "your files to assets,or ",
    SHOPPING_TIPS_IMAGE_UPLOAD_2: "browse",
    SHOPPING_BUTTON_LEAVE_REVIEW: "Leave Review",
    SHOPPING_BUTTON_DRAG_DROP: "DRAG AND DROP",
    SHOPPING_BUTTON_CHECKOUT: "Checkout",
    SHOPPING_SHARE_LINK_TEXT_PRICE: "PRICE",
    SHOPPING_SHARE_LINK_TEXT_NEW_AGE_PV: "NewAge PV",
    SHOPPING_BUTTON_QR_CODE: "QR code",
    SHOPPING_BUTTON_COPY_LINK: "Copy Link",
    SHOPPING_BUTTON_QUICK_SHARE: "Quick Share",
    SHOPPING_BUTTON_DOWNLOAD_IMAGE: "download image",
    SHOPPING_BUTTON_X: "X",
    SHOPPING_BUTTON_CANCEL: "Cancel",
    SHOPPING_CART_TEXT_PRICE: "Price",
    SHOPPING_CART_TEXT_KWIKASH: "Kwikcash",
    SHOPPING_CART_TEXT_SHIP_FROM: "Ship from",
    SHOPPING_CART_TEXT_SUBTOTAL: "Subtotal",
    SHOPPING_BUTTON_CHANGE: "Change",
    SHOPPING_TITLE_COUPON: "Coupon",
    SHOPPING_OPTION_SELECT_COUPON: "Select a Coupon Available to You",
    SHOPPING_REQUIRED_INPUT_CARD_NAME: "Please input your card name!",
    SHOPPING_REQUIRED_INPUT_CARD_NUMBER: "Please input your card number!",
    SHOPPING_TEXT_EXPIRATION_DATE: "Expiration Date",
    SHOPPING_TEXT_EXPIRATION_YEAR: "Expiration Year",
    SHOPPING_REQUIRED_INPUT_CARD_CVV: "Please input your card CVV!",
    SHOPPING_REQUIRED_INPUT_FIRST_NAME: "Please input your firstName!",
    SHOPPING_REQUIRED_INPUT_LAST_NAME: "Please input your lastName!",
    SHOPPING_REQUIRED_INPUT_ADDRESS_1: "Please input your Address Line1!",
    SHOPPING_REQUIRED_INPUT_STATE: "Please select your state!",
    SHOPPING_REQUIRED_INPUT_CITY: "Please input your city!",
    SHOPPING_REQUIRED_INPUT_ZIP: "Please input your zip!",
    SHOPPING_REQUIRED_INPUT_PHONE: "Please input your Address phone!",
    SHOPPING_TEXT_SHIPPING_HANDING: "Shipping and Handling",
    SHOPPING_TEXT_SHOW_SAVED_CARD: "Show Saved Cards",
    SHOPPING_SUCCESS_TEXT_CONGRATS:
      "Congrats! Earn Kwik cash every time someone buys from your promo code! Click the Share button below.",
    SHOPPING_PAYMENT_TEXT_PAYMENT_SUCCESSFUL: "Payment Successful!",
    SHOPPING_PAYMENT_TEXT_SHARE_LINK: "SHARE YOUR LINK",
    SHOPPING_PAYMENT_TEXT_ORDER_ACCEPTED: "Order Accepted",
    SHOPPING_PASSWORD_TITLE_FORGOT: "Forgot Your Password",
    SHOPPING_PASSWORD_TEXT_VERIFY: "To verify that it is you",
    SHOPPING_PASSWORD_TEXT_ENTER_EMAIL:
      "Enter your registered email below to receive password \n reset link instructions",
    SHOPPING_PASSWORD_LABEL_EMAIL: "Email address",
    SHOPPING_PASSWORD_PLACEHOLDER_EMAIL: "Email address",
    SHOPPING_PASSWORD_REQUIRED_EMAIL: "E-mail Is required",
    SHOPPING_PASSWORD_BUTTON_RESET: "Reset Password",
    SHOPPING_PASSWORD_SEND_EMAIL: "An email has been sent!",
    SHOPPING_PASSWORD_SEND_CHECK_INBOX:
      " Please check your inbox and click the received link to\n reset password.",
    SHOPPING_PASSWORD_SEND_BACK_TO_LOGIN: "Back To Login",
    SHOPPING_PASSWORD_SEND_TEXT_DONT_RECEIVE: 'Don"t you receive the link?',
    SHOPPING_PASSWORD_SEND_BUTTON_RESEND: "Resend",
    SHOPPING_PASSWORD_SEND_BUTTON_BACK: "Back",
    HEADER_PHOTO_EDIT_IMAGE: "Edit image",
    HEADER_PHOTO_OK: "OK",
    HEADER_PHOTO_CANCEL: "Cancel",
    HEADER_HOME: "Home",
    HEADER_KWIKCASH: "KwikCash",
    HEADER_CAMPAIGNS: "Campaigns",
    HEADER_ORDER_HISTORY: "Order History",
    HEADER_SIGN_OUT: "Sign out",
    HEADER_LOGOUT: "Logout",
    LOGIN_HEADER_TITLE_REPORT_ISSUE: "Report an Issue",
    LOGIN_HEADER_LABEL_SUBJECT: "Subject",
    LOGIN_HEADER_PLACEHOLDER_SUBJECT: "Summarize your issue here",
    LOGIN_HEADER_LABEL_DESCRIPTION: "Description",
    LOGIN_HEADER_PLACEHOLDER_DESCRIPTION: "Type out issue in detail here..."
  }
};
