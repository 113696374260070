import React, { useEffect, useState, useMemo, useContext } from "react";
import { Decrypt, Encrypt } from "../../utils/encrypt";

let permissions = [];
let staffAuthority = sessionStorage.getItem('staffAuthority');
function getPermissions() {
  let staffAuthorityStr = Decrypt(staffAuthority);
  permissions = JSON.parse(staffAuthorityStr);
}

function PermissionsControl({ children, name = '' }) {
  const [showChildren, setShowChildren] = useState(false);
  const permissionsList = [1, 2, 3]
  useEffect(() => {
    if (sessionStorage.getItem('vendorIdentityType') && permissionsList.indexOf(parseInt(sessionStorage.getItem('vendorIdentityType'))) > -1 && staffAuthority) {
      getPermissions();
      if (permissions.indexOf(name) > -1) {
        setShowChildren(true);
      }
    } else {
      setShowChildren(true);
    }

  }, [])
  return (showChildren) ? (children) : (null)
}

export default PermissionsControl