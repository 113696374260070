import { countryArray } from '../../config/webConfig'

const initialState = {
  cards: {
    list: [],
    current: 0,
    show: true
  },
  // preOrderResult: {
  //     subTotal: null,
  //     shippingFee: null,
  //     total: null
  // },
  preOrderResult: {
    // list: [],
    // current: 0,
  },
  shippingAddress: [],
  usedCouponList: [],
  orderLineList: [],
  vendorShippingMethods: [],
  shippingMethodsItem: []

}

function getLocalAddress() {
  let checkoutAddress = JSON.parse(sessionStorage.checkoutAddress);
  let item = {
    shipToAddr1: checkoutAddress.address1,
    shipToAddr2: checkoutAddress.address2,
    shipToCity: checkoutAddress.city,
    countryCode: countryArray().find((d) => checkoutAddress.country === d.country).countryCode,
    shipToEmail: checkoutAddress.email,
    shipToPhone: checkoutAddress.phone,
    shipToName: checkoutAddress.firstName + ' ' + checkoutAddress.lastName,
    shipToStateCode: checkoutAddress.state,
    shipToPostalCode: checkoutAddress.zip,
    id: 'a1'
  }
  // let campaignId = sessionStorage.getItem('campaignId');
  if (item.countryCode === 'JPN') {
    item.str = checkoutAddress.zip + ' ' + checkoutAddress.state + ' ' + checkoutAddress.city + ' ' + checkoutAddress.address1 + ' ' + checkoutAddress.address2;
  } else {
    item.str = checkoutAddress.address1 + ' ' + checkoutAddress.address2 + ' ' + checkoutAddress.city + ' ' + checkoutAddress.state + ' ' + checkoutAddress.zip;
  }
  return item;
}

const checkoutPayments = (state = initialState, action) => {
  let cards = undefined;
  let preOrderResult = undefined;
  switch (action.type) {
    case 'UPDATE_SELECT_CARD':
      cards = Object.assign({}, state.cards);
      cards.current = action.index;
      return {
        ...state,
        cards
      };
    case 'SET_CHECKOUT_SUMMARY_DATA':
      cards = Object.assign(state.cards);
      cards.list = action.creditCardList || [];
      if (cards.list.length === 0) {
        cards.show = false;
      } else {
        cards.show = true;
      }
      let shippingAddress = JSON.parse(sessionStorage.getItem('checkoutAddressList') || '[]');
      shippingAddress = shippingAddress.map((data, index) => {
        data.id = index + '';
        return data;
      })
      if (sessionStorage.checkoutAddress) {
        shippingAddress.unshift(getLocalAddress());
      }
      preOrderResult = Object.assign({}, state.preOrderResult);
      preOrderResult = action.preOrderResult || {};
      return {
        ...state,
        cards,
        preOrderResult,
        shippingAddress,
        usedCouponList: action.usedCouponList || [],
        orderLineList: action.orderLineList || [],
        remainDiscount: action.remainDiscount || ''
      }
    case 'SET_CHECKOUT_ADDRESS_DATA':
      let _shippingAddress = Object.assign([], state.shippingAddress);
      if (sessionStorage.checkoutAddress) {
        let item = getLocalAddress();
        if (_shippingAddress[0] && _shippingAddress[0].id === 'a1') {
          _shippingAddress[0] = item;
        } else {
          _shippingAddress.unshift(item);
        }
      }
      return {
        ...state,
        shippingAddress: _shippingAddress
      }
    case 'TOGGLE_CARD_SHOW_STATE':
      let wCards = Object.assign({}, state.cards);
      wCards.show = action.show;
      return {
        ...state,
        cards: wCards
      }
    case 'SELECT_ORDER_RESULT':
      preOrderResult = Object.assign({}, state.preOrderResult);
      preOrderResult.current = action.current;
      return {
        ...state,
        preOrderResult
      }
    case 'CHECKOUT/SET_VENDOR_SHIPPING_METHODS':
      return {
        ...state,
        vendorShippingMethods: action.vendorShippingMethods
      }
    case 'CHECKOUT/SET_SHIPPING_METHODS_ITEM':
      return {
        ...state,
        shippingMethodsItem: action.shippingMethodsItem
      }
    default:
      return state
  }
}

export default checkoutPayments