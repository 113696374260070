import React from 'react';
import {getKcVendorCnst} from "../requests/product";

export const GetVendorList = (success) =>{
    let vendorListStr = sessionStorage.getItem('vendorList');
    if (vendorListStr === null) {
        getKcVendorCnst().then(res => {
            if (res.data.errorCode === "200") {
                let vendorList = res.data.response.vendorList;
                sessionStorage.setItem('vendorList', JSON.stringify(vendorList));
                success(vendorList)
            }
        });
    } else {
        success(JSON.parse(vendorListStr));
    }
}