import qs from "qs";
import service from "../../utils/service"

// 获取产品分类属性
export const getProdTypeOrAttr = () => {
  return service.get('/vendor/product/constant');
}
// 提交产品
export const saveProduct = (data) => {
  return service.post('/vendor/product/saveProduct', data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}
// 提交产品新
export const saveProductNew = (data) => {
  return service.post('/vendor/newProduct/saveProduct', data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}
// 获取产品列表
export const getProdList = (data) => {
  return service.get('/vendor/product', {
    params: data,
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}
// 获取产品列表新
export const getProdListNew = (data) => {
  return service.post('/vendor/newProduct/getProductList', data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}
// 获取产品列表新 overview部分
export const getProductOverview = (data) => {
  return service.post('/vendor/newProduct/getProductOverview', data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}
// push新产品
export const pushProduct = (data) => {
  return service.post('/vendor/newProduct/pushProduct', data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}
// 获取产品详情
export const getProdListDetail = (skuNumber, data) => {
  return service.get(`/vendor/product/${skuNumber}`, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}
// 获取产品详情新
export const getProductDetailNew = (data) => {
  return service.post('/vendor/newProduct/getProduct', data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}
// 获取其他国家配置列表
export const getProductCountryList = (data) => {
  return service.post('/vendor/newProduct/getProductCountryList', data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

// 获取已有国家配置列表
export const getProductCountryListExist = (data) => {
  return service.post('/vendor/newProduct/getProductCountryListExist', data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

// 保存其他国家配置列表
export const saveProductCountry = (data) => {
  return service.post('/vendor/newProduct/saveProductCountry', data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

// 保存国际化
export const saveProductLanguage = (data) => {
  return service.post('/vendor/newProduct/saveProductLanguage', data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

// 获取variants顶部菜单
export const getKcVuProductVariantToManager = (data) => {
  return service.post('/vendor/product/getKcVuProductVariantToManager', qs.stringify(data), {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}
// 验证skuNumber
export const checkSkuNumber = (data) => {
  return service.get(`/vendor/product/checkSkuNumber`, {
    params: data
  });
}
// 获取库存数据列表
export const getInventoryList = (data) => {
  return service.get(`/vendor/product/getWarehouseInventory`, {
    params: data,
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}
// 产品图片上传
export const uploadProductImage = (data) => {
  return service.post('/upload/uploadProductImage', data);
}
// 获取vendor列表
export const getKcVendorCnst = () => {
  return service.get('/vendor/getKcVendorCnst', {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

// 获取vendor category 常量
export const getVendorCnstInfoList = (data) => {
  return service.get('/vendor/getVendorCnstInfoList', {
    params: data,
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

//获取warehouse列表
export const getWareHouseList = (data) => {
  return service.get('/warehouse/getKcWarehouseList', {
    params: data,
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

//获取warehouse 库存数据
export const getWarehouseProductId = (data) => {
  return service.get('/warehouse/getWarehouseProductId', {
    params: data,
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

//获取campaign列表
export const getCnstCampaignProducts = (data) => {
  return service.post('/vendor/customer/getCnstCampaignProducts', qs.stringify(data), {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

//商品详情页获取国家货币等信息
export const getCountryWarehouseCurrencyList = (type) => {
  return service.get('/common/getCountryWarehouseCurrencyList?type=' + type, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

//获取其他语言产品信息
export const getI18N = (data) => {
  return service.post('/product/getProductLozalication', qs.stringify(data), {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  })
}

//保存其他语言产品信息
export const saveOrUpdateI18n = (data) => {
  return service.post('/product/saveOrUpdateI18n', data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  })
}

//复制产品
export const copyProduct = (data) => {
  return service.post('/vendor/product/copyProduct', data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  })
}

//删除产品
export const deleteProduct = (data) => {
  return service.post('/vendor/newProduct/updateProductStatus', data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  })
}


//新的复制产品
export const copyProductList = (data) => {
  return service.post('/vendor/product/copyProductList', data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  })
}

// 获取price数据列表
export const getVdrPricing = (data) => {
  return service.get('/vendor/getVdrPricing', {
    params: data,
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

// 获取vendor admin
export const getVdrPricingList = (data) => {
  return service.post('/vendor/getVdrPricingList', data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

// 获取price pro cb数据
export const getVdrPricingProCb = (data) => {
  return service.get('/vendor/getVdrPricingProCb', {
    params: data,
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

// 获取kcash
export const getSkuKCash = (data) => {
  return service.get('/vendor/product/getSkuKCash', {
    params: data,
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

// 获取warehouse列表
export const getWarehouseList = (data) => {
  return service.get('/vendor/product/getWarehouseList', {
    params: data,
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

// 更新WarehouseInventory
export const updateWarehouseInventory = (data) => {
  return service.get('/vendor/product/updateWarehouseInventory', {
    params: data,
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

// 更新WarehouseInventoryModal
export const saveProductInvetory = (data) => {
  return service.post('/vendor/newProduct/saveProductInvetory', data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

// 获取库存数据列表
export const getProductInvetoryList = (data) => {
  return service.post(`/vendor/newProduct/getProductInvetoryList`, data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}

// 获取channel常量数据
export const getChannelList = (data) => {
  return service.post(`/vendor/newProduct/getProductContact`, data, {
    headers: {
      token: sessionStorage.getItem('vendorToken')
    }
  });
}