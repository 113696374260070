import asyncComponent from "../components/AsyncComponent";

let shoppingRouter = [
  {
    path: "/cpgnListOld",
    component: asyncComponent(() =>
      import("../views/shopping/CpgnList/CpgnList")
    ),
    exact: true
  },
  {
    path: "/cpgnList",
    component: asyncComponent(() => import("../views/shopping/CpgnListNew")),
    exact: true
  },
  {
    path: "/cpgnListSearch",
    component: asyncComponent(() => import("../views/shopping/CpgnListSearch")),
    exact: true
  },
  {
    path: "/category",
    component: asyncComponent(() =>
      import("../views/shopping/CpgnListCategory")
    ),
    exact: true
  },
  {
    path: "/brandList",
    component: asyncComponent(() =>
      import("../views/shopping/CpgnListBrandList")
    ),
    exact: true
  },
  // {
  //     path: '/shareLink/cpgnList/:sponsorId',
  //     component: asyncComponent(() => import("../views/shopping/CpgnList")),
  //     exact: true
  // },
  {
    path: "/cpgnList/:brandId/:countryCode?/:hideCart?",
    component: asyncComponent(() =>
      import("../views/shopping/AllProduct/AllProduct")
    ),
    exact: true
  },
  {
    path: "/sharing/:brandId/:cpgnId",
    component: asyncComponent(() =>
      import("../views/shopping/AllProductForShare/AllProduct")
    ),
    exact: true
  },
  {
    path: "/buy/:brandId/:cpgnId",
    component: asyncComponent(() =>
      import("../views/shopping/AllProductForShare/AllProduct")
    ),
    exact: true
  },
  {
    path: "/shareLink/cart",
    component: asyncComponent(() => import("../views/shoppingShareLink/Cart")),
    exact: true
  },
  {
    path: "/cpgnDetail/:cpgnId/:countryCode?/:hideCart?",
    component: asyncComponent(() =>
      import("../views/shopping/CpgnDetail/CpgnDetail")
    )
  },
  {
    path: "/cart",
    component: asyncComponent(() =>
      import("../views/shopping/cartInit/index.js")
    )
  },
  {
    path: "/cartInit",
    component: asyncComponent(() =>
      import("../views/shopping/cartInit/index.js")
    )
  },
  {
    path: "/checkout/address",
    component: asyncComponent(() => import("../views/shopping/CheckoutAddress"))
  },
  {
    path: "/checkout/cards",
    component: asyncComponent(() =>
      import("../views/shopping/CheckoutPayments")
    ),
    exact: true
  },
  {
    path: "/checkout/success",
    component: asyncComponent(() =>
      import("../views/shoppingShareLink/post/PostConfirmation")
    ),
    exact: true
  },
  {
    path: "/checkout/summary",
    component: asyncComponent(() =>
      import("../views/shopping/CheckoutSummary")
    ),
    exact: true
  },
  {
    path: "/newCheckout",
    component: asyncComponent(() => import("../views/shopping/newCheckout")),
    exact: true
  },
  {
    path: "/payment/callback",
    component: asyncComponent(() =>
      import("../views/shopping/PaymentCallback")
    ),
    exact: true
  },
  {
    path: "/checkout/login/summary",
    component: asyncComponent(() =>
      import("../views/shopping/CheckoutLoginSummary")
    ),
    exact: true
  },
  {
    path: "/order",
    component: asyncComponent(() => import("../views/shopping/OrderHistory")),
    exact: true
  },
  {
    path: "/order/detail/:orderId",
    component: asyncComponent(() => import("../views/shopping/OrderDetail")),
    exact: true
  },
  {
    path: "/refund/success",
    component: asyncComponent(() => import("../views/shopping/Refund/success")),
    exact: true
  },
  // shareLink购买页面
  {
    path: "/shareLink/multiple",
    component: asyncComponent(() =>
      import("../views/shoppingShareLink/multipleProd/MultipleProd")
    ),
    exact: true
  },
  {
    path: "/shareLink/cpgnDetail",
    component: asyncComponent(() =>
      import("../views/shoppingShareLink/cpgnDetail/CpgnDetail")
    ),
    exact: true
  },
  {
    path: "/shareLink/cpgnList/:brandId/:cpgnId",
    component: asyncComponent(() =>
      import("../views/shoppingShareLink/AllProduct/AllProduct")
    ),
    exact: true
  },
  {
    path: "/shareLink/login",
    component: asyncComponent(() =>
      import("../views/shoppingShareLink/login/index")
    ),
    exact: true
  },
  {
    path: "/checkout/shareLink/address",
    component: asyncComponent(() =>
      import("../views/shoppingShareLink/checkoutAddress/CheckoutAddress")
    ),
    exact: true
  },
  {
    path: "/checkout/shareLink/card",
    component: asyncComponent(() =>
      import("../views/shoppingShareLink/checkoutCard/CheckoutCard")
    ),
    exact: true
  },
  {
    path: "/checkout/shareLink/summary",
    component: asyncComponent(() =>
      import("../views/shoppingShareLink/checkOutSummary/CheckoutSummary")
    ),
    exact: true
  },
  {
    path: "/checkout/shareLink/success",
    component: asyncComponent(() =>
      import(
        "../views/shoppingShareLink/checkoutPaymentsSuccess/CheckoutPaymentsSuccess"
      )
    ),
    exact: true
  },
  {
    path: "/shareLink/init",
    component: asyncComponent(() => import("../views/shoppingShareLink/Init")),
    exact: true
  },
  {
    path: "/shareLink/cartInit",
    component: asyncComponent(() =>
      import("../views/shoppingShareLink/newCart/cartInit/init")
    ),
    exact: true
  },
  {
    path: "/shareLink/cartLogin",
    component: asyncComponent(() =>
      import("../views/shoppingShareLink/newCart/login")
    ),
    exact: true
  },
  {
    path: "/shareLink/cartRegister",
    component: asyncComponent(() =>
      import("../views/shoppingShareLink/newCart/register")
    ),
    exact: true
  },
  {
    path: "/shareLink/newCheckout",
    component: asyncComponent(() =>
      import("../views/shoppingShareLink/newCart/newCheckout")
    ),
    exact: true
  },
  {
    path: "/shareLink/newCheckoutd",
    component: asyncComponent(() =>
      import("../views/shoppingShareLink/newCart/newCheckoutDemo")
    ),
    exact: true
  }
];

export default shoppingRouter;
