import asyncComponent from "../components/AsyncComponent";

let WeLink = [
  {
    path: '/weLink/product',
    component: asyncComponent(() => import("../views/weLink/Product")),
    exact: true
  },
  {
    path: '/weLink/address',
    component: asyncComponent(() => import("../views/weLink/Address")),
    exact: true
  },
  {
    path: '/weLink/payments/success',
    component: asyncComponent(() => import("../views/weLink/PaymentsSuccess")),
    exact: true
  },
  {
    path: '/weLink/product/detail/:cpgnId/:shareLinkKey',
    component: asyncComponent(() => import("../views/weLink/ProdDetail")),
    exact: true
  },
  {
    path: '/weLink/cart',
    component: asyncComponent(() => import("../views/weLink/WeLinkCart")),
    exact: true
  },
  {
    path: '/weLink/register/step1',
    component: asyncComponent(() => import("../views/weLink/CreateAccount1")),
    exact: true
  },
  {
    path: '/weLink/register/step2',
    component: asyncComponent(() => import("../views/weLink/CreateAccount2")),
    exact: true
  },
  {
    path: '/weLink/register/step3',
    component: asyncComponent(() => import("../views/weLink/CreateAccount3")),
    exact: true
  },
  {
    path: '/weLink/ercSurvey',
    component: asyncComponent(() => import("../views/weLink/ErcSurvey/index")),
    exact: true
  }
]

export default WeLink