import React, { useEffect, useState } from "react";
import { Button, Divider, Form, message, Modal, Upload } from "antd";
import "./style.scss";
import "../public.scss";
import { CloseOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Checkbox, Radio } from "antd";
import { useTranslation } from "react-i18next";
import HintModal from "../../../../../components/hintModal/Index";
import ClipboardJS from "clipboard";
import { Toast } from "antd-mobile";
function ShareSignUpLink({
  showSignUpModal,
  modalClose,
  submitHandle,
  submitData,
  setSubmitData,
  prodImageBeforeUpload,
  statusList,
  submitDataHandleJustCopy
}) {
  const { t } = useTranslation();
  const { Dragger } = Upload;
  // 复制成功框
  const [copySuccessHint, setCopySuccessHint] = useState(false);

  // 复制shareLink
  useEffect(() => {
    let time = null;
    let clipboard = new ClipboardJS(".copyLinkButton");
    clipboard.on("success", function(e) {
      // clearTimeout(time);
      // setCopySuccessHint(true);
      // time = setTimeout(() => {
      //     setCopySuccessHint(false);
      // }, 3000);
      // e.clearSelection();
    });

    let clipboard2 = new ClipboardJS(".copyLinkButton2");
    clipboard2.on("success", function(e) {});
  }, []);

  const statusChange = e => {
    setSubmitData({ ...submitData, status: e.target.value });
  };

  const onCancelHandle = () => {
    modalClose();
  };

  const campaignsSelectChange = (e, id) => {
    const { campaigns } = submitData;
    const campaigns_ =
      campaigns &&
      campaigns.map(item => {
        if (item.id === id) {
          item.selected = e.target.checked;
        }
        return item;
      });

    const submitCampaigns =
      campaigns_ &&
      campaigns_
        .filter(item => {
          return item.selected === true;
        })
        .map(item => {
          return item.id;
        });
    console.log("submitCampaigns", submitCampaigns);
    setSubmitData({
      ...submitData,
      campaigns: campaigns_,
      submitCampaigns: submitCampaigns
    });
  };

  const onSubmitHandle = () => {
    submitHandle();
  };

  return (
    <div>
      <Modal
        visible={showSignUpModal}
        onCancel={modalClose}
        footer={null}
        closable={false}
        maskClosable={true}
        wrapClassName="sign-up-link-modal"
        width={721}
      >
        <div className="close-btn">
          <div className="close" onClick={modalClose}>
            <CloseOutlined />
          </div>
        </div>
        <div className="sign-up-link-modal-body">
          <div className="share-sign-title">
            {t("AMBASSADORS_TITLE_SHARE_SIGN_UP_LINK")}
          </div>
          <div className="share-sign-message">
            {t("AMBASSADORS_MESSAGE_SIGN_UP_LINK")}
          </div>
          <div className="share-sign-message">
            {t("AMBASSADORS_MESSAGE_LEARN_1")}
            <a
              target="_blank"
              href="https://kwik-blog.ghost.io/co-branded-sign-up-page/"
            >
              {t("AMBASSADORS_MESSAGE_LEARN_2")}
            </a>
          </div>
          <div className="share-sign-title2">
            {t("AMBASSADORS_TITLE_SIGN_UP_LINK")}
          </div>
          <div className="share-sign-message2">
            {t("AMBASSADORS_MESSAGE_COPY_LINK")}
          </div>
          <div className="share-sign-copy-link">
            <span className="share-sign-link-text">
              {submitData.vendorLink}
            </span>
            <a onClick={submitDataHandleJustCopy}>
              <div
                className="copy-link-button copyLinkButton"
                data-clipboard-text={
                  submitData.campaigns.length !== 0 ? submitData.vendorLink : ""
                }
              >
                {t("AMBASSADORS_BUTTON_COPY_LINK")}
              </div>
            </a>
          </div>
          <div className="left-right-layout">
            <div className="modal-public campaigns-name-title">
              {t("AMBASSADORS_TITLE_SELECT_CAMPAIGNS")}
            </div>
            <div className="modal-public campaigns-status-message">
              {t("AMBASSADORS_MESSAGE_SELECT_CAMPAIGNS")}
            </div>
            <div className="modal-public campaigns-item">
              {submitData.campaigns &&
                submitData.campaigns.map(item => {
                  return (
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      key={item.id}
                    >
                      <Checkbox
                        style={{ minWidth: 200 }}
                        checked={item.selected}
                        onChange={e => {
                          campaignsSelectChange(e, item.id);
                        }}
                      >
                        {item.name}
                      </Checkbox>
                      <Checkbox
                        style={{ marginLeft: 20 }}
                        checked={item.inviteOption == 1}
                        onChange={e => {
                          const tempObj = Object.assign({}, submitData);
                          tempObj.campaigns.map(campaign => {
                            if (campaign.id == item.id) {
                              campaign.inviteOption = e.target.checked ? 1 : 0;
                            }
                          });
                          setSubmitData(tempObj);
                        }}
                      >
                        With 'Invite a Friend' Option
                      </Checkbox>
                    </div>
                  );
                })}
            </div>
            <div className="modal-public assign-a-status">
              {t("AMBASSADORS_BUTTON_ASSIGN_STATUS")}
            </div>
            <div className="modal-public status-item">
              <Radio.Group
                onChange={e => {
                  statusChange(e);
                }}
                value={submitData.status * 1}
              >
                {statusList &&
                  statusList.map(item => {
                    return (
                      <Radio key={item.id} value={item.id}>
                        {item.name}
                      </Radio>
                    );
                  })}
              </Radio.Group>
            </div>
            <div className="left-image-upload">
              <div className="general-form">
                <span className="upload-image-title">
                  {t("AMBASSADORS_TITLE_UPLOAD_IMAGE")}
                </span>
                <div className="upload-image-message">
                  {t("AMBASSADORS_TITLE_UPLOAD_IMAGE_TEXT")}
                </div>
                <Form>
                  {submitData.imageUrl && (
                    <Button
                      onClick={() => {
                        setSubmitData({ ...submitData, imageUrl: "" });
                      }}
                      className="black-btn"
                    >
                      {t("AMBASSADORS_BUTTON_REMOVE")}
                    </Button>
                  )}
                  <Form.Item className="card">
                    <Dragger
                      className="image-upload"
                      action=""
                      showUploadList={false}
                      beforeUpload={prodImageBeforeUpload}
                    >
                      {submitData.imageUrl === "" ? (
                        <>
                          <img
                            src={require("../img/image-upload.svg")}
                            alt=""
                          />
                          <div className="image-upload-text1">
                            {t("AMBASSADORS_TITLE_UPLOAD_TEXT")}
                          </div>
                          <div className="image-upload-text2">
                            {t("AMBASSADORS_TITLE_DROP_TIPS")}
                          </div>
                        </>
                      ) : (
                        <img
                          src={submitData.imageUrl}
                          alt=""
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      )}
                    </Dragger>
                  </Form.Item>
                  <p className="help-text">
                    {t("AMBASSADORS_TITLE_UPLOAD_SUGGEST_1")}
                    <br />
                    {t("AMBASSADORS_TITLE_UPLOAD_SUGGEST_2")}
                  </p>
                </Form>
              </div>
            </div>
            <div className={"modal-public buttons-area"}>
              <a onClick={onCancelHandle}>
                <div className={"button-cancel"}>
                  {t("AMBASSADORS_BUTTON_CANCEL")}
                </div>
              </a>
              <a onClick={onSubmitHandle}>
                <div
                  className="button-copy copyLinkButton2"
                  data-clipboard-text={submitData.vendorLink}
                >
                  {t("AMBASSADORS_BUTTON_COPY_LINK")}
                </div>
              </a>
            </div>
          </div>
        </div>
      </Modal>
      <HintModal
        visible={copySuccessHint}
        message={t("COPY_SUCCESS")}
        onClose={() => {
          setCopySuccessHint(false);
        }}
      />
    </div>
  );
}

export default ShareSignUpLink;
