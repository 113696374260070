import asyncComponent from "../components/AsyncComponent";

let campaigns = [
  {
    path: "/campaigns",
    component: asyncComponent(() => import("../views/campaigns/Campaigns")),
    exact: true,
  },
  {
    path: "/campaigns/cashWave/:cpgnId",
    component: asyncComponent(() => import("../views/campaigns/CashWave")),
    exact: true,
  },
  {
    path: "/campaigns/cashWave/:cpgnId/productId/:productId",
    component: asyncComponent(() => import("../views/campaigns/CashWave")),
    exact: true,
  },
  {
    path: "/campaigns/cashWave/:cpgnId/multiShare",
    component: asyncComponent(() => import("../views/campaigns/MultiShare")),
    exact: true,
  },
  {
    path: "/campaigns/cashWave/:cpgnId/customerViewData",
    component: asyncComponent(() =>
      import("../views/campaigns/CustomerViewData")
    ),
    exact: true,
  },
  {
    path: "/campaigns/customerViewAllData",
    component: asyncComponent(() =>
        import("../views/campaigns/CustomerViewAllData")
    ),
    exact: true,
  },
  {
    path: "/campaigns/cashWave/:cpgnId/waveDetail/:wave",
    component: asyncComponent(() => import("../views/campaigns/WaveDetail")),
    exact: true,
  },
  {
    path: "/campaigns/cashWave/waveDetail/:wave",
    component: asyncComponent(() => import("../views/campaigns/WaveAllDetail")),
    exact: true,
  },
];

export default campaigns;
