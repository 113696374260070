import qs from "qs";
import service from "../utils/service";
import getLanguage from "../utils/getLanguage";
import sessionCache from "../utils/sessionCache";

// 获取产品列表
export const getCpgnList = data => {
  data.lang = getLanguage().toLowerCase();
  return service.post("/product/getCampaignListByCustId", qs.stringify(data), {
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};
// 获取review列表
export const getReviewList = data => {
  return service.get(`/review/getReviewList`, {
    params: data
  });
};
// 获取产品列表多国家
export const getAllProductList = data => {
  // data.lang = getLanguage().toLowerCase();
  return service.get("/product/getAllProductList", {
    params: data || {}
  });
};

// 获取产品列表，带分页
export const getAllProductListWithPage = data => {
  // data.lang = getLanguage().toLowerCase();
  return service.get("/vendor/product/getVendorProductListHomeWithPage", {
    params: data || {}
  });
};

// 获取单个vendor产品列表
export const getVendorProductListWithPage = data => {
  return service.get("/vendor/product/getVendorProductListWithPage", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};
// 获取产品详情
// export const getCpgnDetailByCId = (data) => {
//     data.lang = getLanguage().toLowerCase();
//     return service.post("/product/getProductDetail", qs.stringify(data), {
//         headers: {
//             token: sessionCache().getItem('apiToken')
//         }
//     });
// }
// 获取结算页信息
// export const getCheckoutInfo = (data) => {
//     return service.post("/jp/preview?lang=" + getLanguage().toLowerCase(), data);
// }
// 提交review
export const insertOrUpdateReview = data => {
  return service.post("/review/insertOrUpdateReview", data);
};
// 获取review详情
export const getReview = data => {
  return service.get("/review/getReview", {
    params: data
  });
};
// 删除review
export const deleteReview = data => {
  return service.get("/review/deleteReview", {
    params: data
  });
};
export const getCheckoutInfo = data => {
  return service.post(
    "/order/preview?lang=" + getLanguage().toLowerCase(),
    data
  );
};
// Get credit cards by customer ID
export const getCreditCard = custId => {
  return service.get(`/creditCard/list?custId=${custId}`, {
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};
/**
 * Remove credit card from saved cards by Ariix token and customer ID
 * @param data { kwikToken: "string", custId: 0 }
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteCreditCard = data => {
  return service.post("creditCard/deleteCreditCard", data, {
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};
// 通过zip获取地址信息
export const getShipAddressByZip = data => {
  return service.post("/jp/getShipAddressByZip", qs.stringify(data));
};
// 提交订单
export const postOrder = data => {
  data.sysType = 2;
  return service.post("/order/postOrder", data, {
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};
// 提交sharelink订单
// export const postEnrollment = (data) => {
//     return service.post("/jp/enrollment", data);
// }
export const postEnrollment = data => {
  data.sysType = 2;
  return service.post("/order/createCustAndOrder", data);
};
// 通过smartLinkKey获取产品数据
export const getProductsBysmartLinkKey = data => {
  data.lang = getLanguage().toLowerCase();
  return service.get("/shareLink/getKwikLinkByKey", {
    params: data
  });
};

// 通过smartLinkKey获取产品数据
export const getCountryKcKwickLinkByKey = data => {
  // data.lang = getLanguage().toLowerCase();
  return service.get("/shareLink/getCountryKcKwickLinkByKey", {
    params: data
  });
};
// 通过购物车产品code获取产品信息
export const getProductInfo4CartView = data => {
  return service.post(
    "/product/getProductInfo4CartView?lang=" + getLanguage().toLowerCase(),
    data
  );
};
// 校验email是否唯一
export const checkEmailUnique = data => {
  return service.post("/common/checkEmailUnique", qs.stringify(data));
};
// 验证邮箱cpgnId绑定状态
export const getSlEmailCheck = data => {
  return service.get(
    `/common/getSlEmailCheck?email=${data.email}&cpgnId=${data.cpgnId}`
  );
};
// 获取订单历史
export const getOrderHistoryList = data => {
  data.lang = getLanguage().toLowerCase();
  data.portal = "WER1";
  return service.get("/orderHistory/vuOrdersHist", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};
// 订单退款
export const refundOrder = data => {
  return service.post("/orderHistory/refundOrder", qs.stringify(data), {
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};
// 获取订单详情
export const getOrderHistoryDetail = data => {
  data.lang = getLanguage().toLowerCase();
  return service.get("/orderHistory/getOrderDetail", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};

// 获取orderLine退款状态
export const getRefundOrders = data => {
  return service.get("/order/getRefundOrders", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};
// 获取state
export const getStateList = countryCode => {
  return service.get(
    "/common/getStateListByCountry?countryCode=" + countryCode
  );
};
// 获取ketone结算页信息
export const getKetoneCheckoutInfo = data => {
  return service.post(
    "/ketone/reviewOrder?lang=" + getLanguage().toLowerCase(),
    data
  );
};
// 提交ketone订单
// export const ketonePostOrder = (data) => {
//     return service.post("/ketone/postOrder", data);
// }
// 提交ketone sharelink订单
// export const postKetoneEnrollment = (data) => {
//     return service.post("/ketone/enrollment", data);
// }
// 验证卡号格式
export const vaildCardTypeSupported = data => {
  return service.post("/common/vaildCardTypeSupported", qs.stringify(data));
};
// 通过用户id获取地址
export const getCustomerShippingAddress = data => {
  return service.post(
    "/common/getCustomerShippingAddress",
    qs.stringify(data),
    {
      headers: {
        token: sessionCache().getItem("apiToken")
      }
    }
  );
};
// 上传头像
export const uploadAvatar = (data, custId) => {
  return service.post("/upload/uploadAvatar", data, {
    params: {
      custId
    }
  });
};
// 创建分享添加媒体
export const uploadMedia = data => {
  return service.post("/upload/uploadMedia", data);
};
// 创建分享添加视频
export const uploadVimeo = data => {
  return service.post("/upload/uploadVimeo", data);
};
// 获取分享视频后缀名
export const getVimeoSuffer = () => {
  return service.get("/shareLink/getVimeoSuffer", {
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};
// 获取视频是否解码完
export const getVimeo = data => {
  return service.get("/upload/getVimeo", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};
// 通过卡号获取卡信息
export const getKcCustCardType = data => {
  return service.post("/common/getKcCustCardType", data, {
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};

export const getVendorInfo = async (vendorId, langId) => {
  const resp = await service
    .get(
      "/vendor/getVuVendorInfoForFront?vendorId=" +
        vendorId +
        "&languageId=" +
        langId,
      {
        headers: {
          token: sessionStorage.getItem("apiToken")
        }
      }
    )
    .catch(err => {
      console.error(err.toString());
    });

  return resp.data.response.vuVendorInfoCommandList[0];
};

// 更新国家和语言
export const updateCustBaseInfo = data => {
  return service.post("/common/updateCustBaseInfo", data, {
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};

// 检查username唯一性
export const checkUsername = data => {
  return service.get("/common/checkUsername", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};

// 提交sharelink来源平台
export const saveLinkRecords = data => {
  return service.post("/shareLink/saveLinkRecords", data, {
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};

//检查这个产品是否可以分享，是否是inactive vendor
export const checkInactiveBrand = data => {
  return service.get("/shareLink/checkInactiveBrand", {
    params: data
  });
};

// 获取shopping category 常量
export const getVendorCnstInfoList = data => {
  return service.get("/vendor/getVendorCnstInfoList", {
    params: data
  });
};

// 获取shopping category 常量
export const getCategoryList = data => {
  return service.get("/shopping/getCategoryList", {
    params: data
  });
};

// new shopping search page
export const getSearchProdList = data => {
  return service.post("/shopping/searchProdList", data, {
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};

// new shopping page
export const getNewShopDataList = data => {
  return service.post("/shopping/getNewShopData", data, {
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};

// new brand list page
export const getAllBrands = data => {
  return service.post("/shopping/getAllBrands", data, {
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};

// new category list page
export const getProductByCategory = data => {
  return service.post("/shopping/getProductList", data, {
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};

// new category list page
export const clickProductDetail = data => {
  return service.post("/shopping/clickProductDetail", data, {
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};

// 获取vendor信息
export const getVendorInfoByVId = data => {
  return service.get("/vendor/getVendorInfo?vid=" + data, {
    vid: data
  });
};

// 获取是否显示shopping菜单
export const isHideShopping = data => {
  return service.get(`/common/isHideShopping/${data.custId}/WER1`, {
    headers: {
      token: sessionCache().getItem("apiToken")
    }
  });
};
