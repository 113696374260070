import React, { useState } from "react";
import "./style.scss";
import { Checkbox, Pagination } from "antd";
import { formatMoneyByCountry } from "../../../../utils/formatMoney";
import { useTranslation } from "react-i18next";
import UserAvatar from "../../../../components/UserAvatar";

function DataTable({
  vendorId,
  campaignId,
  tableData,
  pageSize,
  ambassadors,
  setAmbassadors,
  pageChange,
  reSort,
  searchCondition
}) {
  const { t } = useTranslation();

  const tableTitles = [
    { title: t("AMBASSADORS_DATA_TABLE_TITLE_AMBASSADORS"), type: 1 },
    { title: t("AMBASSADORS_DATA_TABLE_TITLE_CAMPAIGN_STATUS"), type: 2 },
    { title: t("AMBASSADORS_DATA_TABLE_TITLE_STATUS"), type: 3 },
    { title: t("AMBASSADORS_DATA_TABLE_TITLE_EARNINGS"), type: 4 },
    { title: t("AMBASSADORS_DATA_TABLE_TITLE_SALES_GENERATED"), type: 5 },
    { title: t("AMBASSADORS_DATA_TABLE_TITLE_DIRECT_REFERRALS"), type: 6 },
    { title: t("AMBASSADORS_DATA_TABLE_TITLE_INDIRECT_REFERRALS"), type: 7 },
    { title: t("AMBASSADORS_DATA_TABLE_TITLE_CAMPAIGNS"), type: 8 }
  ];

  const [tableSelectAll, setTableSelectAll] = useState(false);

  const selectAllData = e => {
    setTableSelectAll(e.target.checked);
    const newData =
      ambassadors &&
      ambassadors.map(item => {
        item.selected = e.target.checked;
        return item;
      });
    setAmbassadors(newData);
  };

  const itemSelect = (e, id) => {
    const { checked } = e.target;
    if (!checked) {
      setTableSelectAll(false);
    }
    const newData =
      ambassadors &&
      ambassadors.map(item => {
        if (item.id === id) {
          item.selected = checked;
        }
        return item;
      });
    setAmbassadors(newData);
  };

  const viewAmbassadorDetail = item => {
    if (item.custId !== -1) {
      let cpgnId = "";
      if (campaignId !== 0) {
        cpgnId = campaignId;
      }
      let url =
        "/vendor/ambassador/detail?custId=" +
        item.custId +
        "&displayName=" +
        item.ambassadorName +
        "&activeStatus=" +
        item.activeStatus;
      if (cpgnId) {
        url = url.concat("&cpgnId=" + cpgnId);
      }
      url = url.concat("&vendorId=" + vendorId);
      url = url.concat("&searchKey=" + searchCondition.searchKey);
      let statusIds = "";
      searchCondition.statusButtons.map(item => {
        if (item.isSelect) {
          statusIds = statusIds.concat(item.id);
          statusIds = statusIds.concat(",");
        }
      });
      if (statusIds.length > 0) {
        statusIds = statusIds.substr(0, statusIds.length - 1);
      }
      url = url.concat("&status=" + statusIds);
      window.location.href = url;
    }
  };

  return (
    <div className="ambassadors-table">
      <div className="ambassadors-table-header">
        <div className="table-header-item">
          <Checkbox
            key={0}
            checked={tableSelectAll}
            onChange={e => {
              selectAllData(e);
            }}
          />
        </div>
        {tableTitles.map(item => {
          return (
            <div
              key={item.type}
              className="table-header-item"
              style={{ cursor: "pointer" }}
              onClick={() => reSort(item.type)}
            >
              <span>{item.title}</span>
            </div>
          );
        })}
      </div>
      <div className="ambassadors-table-body">
        {ambassadors &&
          ambassadors.map(item => {
            return (
              <div key={item.id}>
                <div className="ambassadors-header-down-line" />
                <div className="table-data-row">
                  <div className="table-body-item">
                    <Checkbox
                      checked={item.selected}
                      onChange={e => {
                        itemSelect(e, item.id);
                      }}
                    />
                  </div>
                  <div className="table-body-item">
                    {/*<img src={item.imageUrl} alt=""/>*/}
                    <UserAvatar
                      width={35}
                      displayName={item.ambassadorName}
                      imageUrl={item.imageUrl}
                    />
                    <a onClick={() => viewAmbassadorDetail(item)}>
                      <span>{item.ambassadorName}</span>
                    </a>
                    {/*<span>{item.ambassadorName}</span>*/}
                  </div>
                  <div className="table-body-item">
                    {/*<div className={"status-area status-color-" + item.statusId}>{AMBASSADOR_STATUS[item.statusId]}</div>*/}
                    <div
                      className={"status-area status-color-" + item.statusCode}
                    >
                      {item.statusId}
                    </div>
                  </div>
                  <div className="table-body-item">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item.activeStatus
                      }}
                    >
                      {}
                    </span>
                  </div>
                  <div className="table-body-item">
                    <span>
                      {formatMoneyByCountry(item.currencyCode, item.earnings)}
                    </span>
                  </div>
                  <div className="table-body-item">
                    <span>
                      {formatMoneyByCountry(
                        item.currencyCode,
                        item.salesGenerated
                      )}
                    </span>
                  </div>
                  <div className="table-body-item">
                    <span>{item.DirectReferrals}</span>
                  </div>
                  <div className="table-body-item">
                    <span>{item.indirectReferrals}</span>
                  </div>
                  <div className="table-body-item">
                    <span>{item.cpgnNames}</span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div
        style={{ textAlign: "right", paddingRight: "10px", marginTop: "16px" }}
      >
        <Pagination
          onChange={pageChange}
          current={tableData?.pageNo}
          defaultCurrent={1}
          total={tableData?.total}
          pageSize={pageSize}
          hideOnSinglePage={true}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
}

export default DataTable;
