import asyncComponent from "../components/AsyncComponent";

let vendor = [
  {
    path: "/vendor/register1",
    component: asyncComponent(() => import("../views/vendor/register1")),
    exact: true,
  },
  {
    path: "/vendor/register2",
    component: asyncComponent(() => import("../views/vendor/register2")),
    exact: true,
  },
  {
    path: "/vendor/register3",
    component: asyncComponent(() => import("../views/vendor/register3")),
    exact: true,
  },
  {
    path: "/vendor/register4",
    component: asyncComponent(() => import("../views/vendor/register4")),
    exact: true,
  },
  {
    path: "/vendor/register5",
    component: asyncComponent(() => import("../views/vendor/register5")),
    exact: true,
  },
  {
    path: "/vendor/register6",
    component: asyncComponent(() => import("../views/vendor/register6")),
    exact: true,
  },
  {
    path: "/vendor/register7",
    component: asyncComponent(() => import("../views/vendor/register7")),
    exact: true,
  },
  {
    path: "/vendor/pending",
    component: asyncComponent(() => import("../views/vendor/register5")),
    exact: true,
  },
  {
    path: "/vendor/profile",
    component: asyncComponent(() => import("../views/vendorProfile")),
    exact: true,
  },
  {
    path: "/vendor/posts",
    component: asyncComponent(() =>
      import("../views/vendorProfile/CreatePost")
    ),
    exact: true,
  },
  {
    path: "/vendor/postitem",
    component: asyncComponent(() => import("../views/vendorProfile/PostItem")),
    exact: true,
  },
  {
    path: "/vendor",
    component: asyncComponent(() =>
      import("../vendorMoudle/containers/App/index")
    ),
  },
  {
    path: "/register/vendor/step1",
    component: asyncComponent(() =>
      import("../views/vendor/register/step1/index")
    ),
  },
  {
    path: "/register/vendor/step2",
    component: asyncComponent(() =>
      import("../views/vendor/register/step2/index")
    ),
  },
  {
    path: "/register/vendor/step3",
    component: asyncComponent(() =>
      import("../views/vendor/register/step3/index")
    ),
  },
  {
    path: "/register/vendor/step4-1",
    component: asyncComponent(() =>
      import("../views/vendor/register/step4-1/index")
    ),
  },
  {
    path: "/register/vendor/step4-2",
    component: asyncComponent(() =>
      import("../views/vendor/register/step4-2/index")
    ),
  },
  {
    path: "/register/vendor/step4-3",
    component: asyncComponent(() =>
      import("../views/vendor/register/step4-3/index")
    ),
  },
  {
    path: "/owner/register/step1",
    component: asyncComponent(() =>
      import("../views/owner/step1/Index")
    ),
  },
  {
    path: "/owner/register/step2",
    component: asyncComponent(() =>
      import("../views/owner/step2/Index")
    ),
  },
  {
    path: "/staff/register/step1",
    component: asyncComponent(() =>
      import("../views/staff/step1/Index")
    ),
  },
  {
    path: "/vendor-forgot/password",
    component: asyncComponent(() =>
        import("../vendorMoudle/containers/ForgotPassword/index")
    ),
  },
  {
    path: "/vendor-forgot/password-send",
    component: asyncComponent(() =>
        import("../vendorMoudle/containers/ForgotPasswordSucessful/index")
    ),
  },
  {
    path: "/vendor-reset/password",
    component: asyncComponent(() =>
        import("../vendorMoudle/containers/ResetPassword/index")
    ),
  },
  {
    path: "/vendor-reset/password-success",
    component: asyncComponent(() =>
        import("../vendorMoudle/containers/ResetPasswordSuccess/index")
    ),
  },
];

export default vendor;
