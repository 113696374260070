import asyncComponent from "../components/AsyncComponent";

let settingRouter = [
  {
    path: "/setting/account",
    component: asyncComponent(() => import("../views/Setting/Account")),
  },
  {
    path: "/setting/contact",
    component: asyncComponent(() => import("../views/Setting/Contact")),
  },
  {
    path: "/setting/legal",
    component: asyncComponent(() => import("../views/Setting/Legal")),
  },
  {
    path: "/setting/finance",
    component: asyncComponent(() => import("../views/Setting/Financial")),
  },
  {
    path: "/user/setting/account",
    component: asyncComponent(() => import("../views/userSetting/Account")),
    exact: true
  },
  {
    path: "/user/setting/address",
    component: asyncComponent(() => import("../views/userSetting/Address")),
    exact: true
  },
  {
    path: "/user/setting/address/detail/:addressId?",
    component: asyncComponent(() => import("../views/userSetting/AddressDetail")),
    exact: true
  },
  {
    path: "/user/setting/creditcards",
    component: asyncComponent(() => import("../views/userSetting/CreditCard")),
    exact: true
  },
  {
    path: "/user/setting/creditcards/detail",
    component: asyncComponent(() => import("../views/userSetting/CreditCardsDetail")),
    exact: true
  },
  {
    path: "/user/setting/privacy",
    component: asyncComponent(() => import("../views/userSetting/Privacy")),
    exact: true
  },
  {
    path: "/user/setting/notification",
    component: asyncComponent(() => import("../views/userSetting/Notification")),
    exact: true
  },
  {
    path: "/user/setting/cashout",
    component: asyncComponent(() => import("../views/userSetting/CashOutMethod")),
    exact: true
  }
];

export default settingRouter;
