import sessionCache from "./sessionCache";

// export let cnstLanguageCommandList = function () { return sessionCache().getItem('cnstLanguageCommandList') ? JSON.parse(sessionCache().getItem('cnstLanguageCommandList')) : [] }();
// export let countryWarseMap = function () { return sessionCache().getItem('countryWarseMap') ? JSON.parse(sessionCache().getItem('countryWarseMap')) : [] }()
// export let getCountryWarseMap = function () { return sessionCache().getItem('countryWarseMap') ? JSON.parse(sessionCache().getItem('countryWarseMap')) : [] }

function _getCnstLanguageCommandList() {
  return sessionCache().getItem("cnstLanguageCommandList")
    ? JSON.parse(sessionCache().getItem("cnstLanguageCommandList"))
    : [];
}
function _getCountryWarseMap() {
  return sessionCache().getItem("countryWarseMap")
    ? JSON.parse(sessionCache().getItem("countryWarseMap"))
    : [];
}

function _getCountryWarseMap4() {
  return sessionCache().getItem("countryWarseMap4")
    ? JSON.parse(sessionCache().getItem("countryWarseMap4"))
    : [];
}

export const getCnstLanguageCommandList = _getCnstLanguageCommandList;
export const getCountryWarseMap = _getCountryWarseMap;
export const getCountryWarseMap4 = _getCountryWarseMap4;
