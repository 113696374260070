import React, { useState, useEffect } from "react";
import sessionCache from "../utils/sessionCache";
import { getCountryWarehouseCurrencyList } from "../vendorMoudle/requests/product";

function LoadingImg() {
  return (
    <div
      className="loadingCont"
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        style={{ maxWidth: "700px", marginTop: "-30px" }}
        src={require("../images/pageLoading.svg")}
        alt=""
      />
    </div>
  );
}

function KCContent(props) {
  const [show, setShow] = useState(false);
  function getCountryConfig() {
    let count = 1;
    if (window.location.href.indexOf("vendor") > -1) {
      count = 2;
    } else {
      count = 1;
    }
    getCountryWarehouseCurrencyList(count).then((res) => {
      if (res.data.errorCode === "200") {
        // 为了排除选择中国市场过滤
        let cnstLanguageCommandList = res.data.cnstLanguageCommandList;
        let cnstLanguageVendorCommandList =
          res.data.cnstLanguageVendorCommandList;
        let countryWarseMap = res.data.countryWarseMap;
        if (count == 1) {
          sessionCache().setItem(
            "cnstLanguageCommandList",
            JSON.stringify(cnstLanguageCommandList)
          );
        }

        if (count == 2) {
          sessionCache().setItem(
            "cnstLanguageCommandList",
            JSON.stringify(cnstLanguageVendorCommandList)
          );
        }

        sessionCache().setItem(
          "countryWarseMap",
          JSON.stringify(countryWarseMap)
        );
        getCountryConfigType4();
      }
    });
  }

  function getCountryConfigType4() {
    getCountryWarehouseCurrencyList(4).then((res) => {
      if (res.data.errorCode === "200") {
        let countryWarseMap = res.data.countryWarseMap;
        sessionCache().setItem(
          "countryWarseMap4",
          JSON.stringify(countryWarseMap)
        );
        setShow(true);
      }
    });
  }
  useEffect(() => {
    if (
      sessionCache().getItem("cnstLanguageCommandList") &&
      sessionCache().getItem("countryWarseMap")
    ) {
      setShow(true);
    } else {
      getCountryConfig();
    }
  }, []);
  return show ? props.children : <LoadingImg />;
}

export default KCContent;
