import React from "react";
import { createContext, useState } from "react";
import sessionCache from "../utils/sessionCache";
import { getSKUInfoByProductIdWithToken, getSKUInfoByProductId } from "../requests/smartLink";

export const CartContext = createContext({
  cartCount: 0,
  updateCartCount: () => { },
  getCartItems: () => { },
  getCartProds: () => { }
});

const CartContextProvider = (props) => {
  const [cartCount, setCartCount] = useState(0);

  const updateCartCount = () => {
    let cartDatas = sessionCache().getItem('cartDatas') ? JSON.parse(sessionCache().getItem('cartDatas')) : [];
    let count = 0;
    cartDatas.forEach(data => {
      count += data.qty;
    });
    setCartCount(count);
  }

  const getCartItems = async () => {
    const cartData = sessionStorage.getItem("cartDatas") ? JSON.parse(sessionStorage.getItem("cartDatas")) : [];
    const qs = cartData.map(product => {
      return `productIds=${product.productId}`;
    });

    const cart = []
    const resp = await getSKUInfoByProductIdWithToken(qs.join("&")).catch(err => { throw err });
    if (resp.data.errorCode === "200") {
      const { vuProductMasterByIdsMap } = resp.data.response;
      cartData.forEach((prod, index) => {
        Object.keys(vuProductMasterByIdsMap).forEach((vId) => {
          Object.keys(vuProductMasterByIdsMap[vId]).forEach((pId) => {
            if (prod.productId == pId) {
              let prodObj = {
                ...prod,
                data: vuProductMasterByIdsMap[vId][pId][prod.selectSkuId],
                vId
              }
              cart.push(prodObj);
            }
          })
        })
      })
    } else {
      throw new Error("bad request")
    }
    return cart;
  }
  const getCartProds = async () => {
    const cartData = sessionStorage.getItem("cartDatas") ? JSON.parse(sessionStorage.getItem("cartDatas")) : [];
    const qs = cartData.map(product => {
      return `productIds=${product.productId}`;
    });

    const cart = []
    const resp = await getSKUInfoByProductId(qs.join("&")).catch(err => { throw err });
    if (resp.data.errorCode === "200") {
      const { vuProductMasterByIdsMap } = resp.data.response;
      cartData.forEach((prod, index) => {
        Object.keys(vuProductMasterByIdsMap).forEach((vId) => {
          Object.keys(vuProductMasterByIdsMap[vId]).forEach((pId) => {
            if (prod.productId == pId) {
              let prodObj = {
                ...prod,
                data: vuProductMasterByIdsMap[vId][pId][prod.selectSkuId],
                vId
              }
              cart.push(prodObj);
            }
          })
        })
      })
    } else {
      throw new Error("bad request")
    }
    return cart;
  }

  return (
    <CartContext.Provider value={{ cartCount, updateCartCount, getCartItems, getCartProds }}>
      {props.children}
    </CartContext.Provider>
  )
}

export default CartContextProvider;