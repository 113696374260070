import React, { createContext, useState } from "react";
import { getBankAccountList } from "../requests/kwikCash";

export const KwikcashContext = createContext({
  accounts: []
})

const KwikcashContextProvider = (props) => {
  const data = JSON.parse(sessionStorage.getItem("bankAccounts"));
  const [accounts, setAccounts] = useState(data ?? []);
  const getAccounts = async () => {
    const custId = sessionStorage.getItem("custId");
    if (!custId) {
      throw new Error("no customer");
    }

    const resp = await getBankAccountList({ custId }).catch(err => { console.error(err) });
    setAccounts(resp.data.response);
    sessionStorage.setItem("bankAccounts", JSON.stringify(resp.data.response));
  }


  return <KwikcashContext.Provider value={{ accounts, getAccounts }}>{props.children}</KwikcashContext.Provider>
}

export default KwikcashContextProvider;