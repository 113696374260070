import React, { useEffect, useState } from "react";
import "./style.scss";
import SearchFilter from "../../components/Ambassadors/SearchFilter";
import DataTable from "../../components/Ambassadors/DataTable";
import FiltersState from "../../components/Ambassadors/FiltersState";
import {
  assignAmbassadorStatus,
  getAmbassadorsData,
  removeAmbassador
} from "../../requests/dashboard";
import { Toast } from "antd-mobile";
import { Tooltip, message } from "antd";
import InviteUsers from "../OverViewN/Modal/InviteUsers";
import { uploadProductImage } from "../../requests/product";
import {
  GenerateVendorLink,
  submitDataInit,
  submitHandle,
  VendorCampaigns
} from "./AmbassadorHandle";
import { useTranslation } from "react-i18next";
import TopToolbar from "../../components/OverView/TopToolbar";
import { GetVendorList } from "../../utils/VendorList";
import cloudPath from "../../../config/cloudPath";
import PermissionsControl from "../../../components/PermissionsControl";
import ShareSignUpLink from "../OverViewN/Modal/ShareSignUpLink";
import { useHistory } from "react-router-dom";

function Ambassadors() {
  const { t } = useTranslation();
  const history = useHistory();
  const [campaignId, setCampaignId] = useState(0);
  const [ambassadors, setAmbassadors] = useState(null);
  const [vendorList, setVendorList] = useState([]);
  const [showFiltersArea, setShowFiltersArea] = useState(true);
  const [currentVendorId, setCurrentVendorId] = useState("");
  const [showInviteUsesModal, setShowInviteUsesModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [filterCampaigns, setFilterCampaigns] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [moreActionAlertStep, setMoreActionAlertStep] = useState(0);
  const [tableReload, setTableReload] = useState(0);
  const [paramVendorId, setParamVendorId] = useState();
  const [vendorReload, setVendorLoad] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [tableData, setTableData] = useState({});
  const [sortOption, setSortOption] = useState(0);

  const pageSize = 10;
  const initSubmitData = {
    campaigns: [],
    vendorLinkKey: "",
    vendorLink: "",
    imageUrl: "",
    status: DEFAULT_STATUS,
    emails: [],
    submitCampaigns: [],
    isSendEmail: true
  };
  const [submitData, setSubmitData] = useState(initSubmitData);
  let statusButtonList = [
    {
      id: 1,
      name: "Active",
      isSelect: true,
      group: 1,
      toolTip:
        "Active: Customers with this status are currently active in your ambassador program. They have set up their Kwik account and shared one of your products within the last 6 months."
    },
    {
      id: 2,
      name: "Inactive",
      isSelect: true,
      group: 1,
      toolTip:
        "Inactive: Customers with this status previously joined your ambassador program by setting up their Kwik account, but have not shared anything about Kwik within the past 6 months."
    },
    {
      id: 3,
      name: "Invited",
      isSelect: false,
      toolTip:
        "Invited: Customers with this status have been invited to join your ambassador program through the Kwik vendor dashboard, but have not yet signed up by creating a Kwik account."
    },
    {
      id: 4,
      name: "Preloaded",
      isSelect: false,
      toolTip:
        "Preloaded: This status applies to customers who have been added to your ambassador program in the Kwik system, but have not yet set up a Kwik account, placed an order, or been invited through the vendor admin."
    },
    {
      id: 5,
      name: "Customer",
      isSelect: false,
      toolTip:
        "Customer: This status applies to customers who have placed an order with your brand in the past, but have not yet signed up for the ambassador program by creating a Kwik account."
    },
    {
      id: 6,
      name: "Removed",
      isSelect: false,
      toolTip:
        "Removed: This status applies to customers who were previously part of your ambassador program but have been forcibly removed by you from the program."
    }
  ];
  const [statusButtons, setStatusButtons] = useState([]);
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [searchCondition, setSearchCondition] = useState({
    searchKey: "",
    statusButtons: []
  });

  // useEffect(()=>{
  //   let ambassadorCondition = sessionStorage.getItem("ambassadorCondition") ? JSON.parse(sessionStorage.getItem("ambassadorCondition")) : null;
  //   let condition ={...ambassadorCondition};
  //   if (condition) {
  //     setSearchKey(condition.searchKey);
  //     setSearchKeyWord(condition.searchKey)
  //     setStatusButtons(initStatus(condition.status));
  //   }
  //   console.log(condition);
  //   console.log(sessionStorage.getItem("ambassadorCondition"))
  //   // sessionStorage.setItem("ambassadorCondition", null);
  // },[])

  useEffect(() => {
    let ambassadorCondition = sessionStorage.getItem("ambassadorCondition")
      ? JSON.parse(sessionStorage.getItem("ambassadorCondition"))
      : {};
    let condition = { ...ambassadorCondition };
    let _searchKey = condition.searchKey;
    setSearchKey(_searchKey ? _searchKey : "");
    setVendorLoad(true);
    setTableReload(0);
    setSearchKeyWord("");
    setCampaignId(0);
    setPageNo(1);
    setTableData({});
    let _statusButtons = initStatus(condition.status);
    setStatusButtons(_statusButtons);

    if (currentVendorId !== "" && currentVendorId !== "0") {
      getTableData(0, _searchKey, 1, 0, _statusButtons);
    } else {
      if (currentVendorId && _searchKey) {
        getTableData(0, _searchKey, 1, 0, _statusButtons);
        sessionStorage.removeItem("ambassadorCondition");
      }
      setAmbassadors(null);
    }

    setFilterCampaigns([]);
    if (currentVendorId !== "" && currentVendorId !== "0") {
      VendorCampaigns(t, currentVendorId, newCampaigns => {
        setFilterCampaigns(newCampaigns);
        setSubmitData({ ...submitData, campaigns: newCampaigns });
      });
    }
  }, [currentVendorId]);

  useEffect(() => {
    if (currentVendorId !== "") {
      setVendorLoad(false);
      if (currentVendorId !== "0" || searchKey !== "") {
        setPageNo(1);
        getTableData(campaignId, searchKeyWord, 1);
      } else {
        setAmbassadors([]);
        setTableData({});
      }
    }
  }, [searchKeyWord]);

  useEffect(() => {
    if (currentVendorId !== "") {
      setVendorLoad(false);
      if (currentVendorId !== "0" || searchKey !== "") {
        setPageNo(1);
        getTableData(campaignId, searchKey, 1);
      } else {
        setAmbassadors([]);
        setTableData({});
      }
    }
  }, [campaignId]);

  useEffect(() => {
    let vendorId = currentVendorId;
    if (sessionStorage.getItem("loginType") === "0") {
      vendorId = sessionStorage.getItem("vendorAdminSelectVendorId");
      GetVendorList(list => {
        setVendorList(list);
      });
    } else {
      vendorId = sessionStorage.getItem("vendorId");
    }
    setCurrentVendorId(vendorId);
  }, []);
  const statusList = Object.entries(AMBASSADOR_STATUS).map(([key, value]) => {
    return { id: key * 1, name: value };
  });

  const initStatus = status => {
    let _status = status;
    let selectedStatusMap = {};
    let _initStatus = statusButtonList;
    if (_status) {
      let statusIds = _status.split(",");
      statusIds.map(item => {
        selectedStatusMap[item] = true;
      });

      _initStatus = _initStatus.map(item => {
        if (selectedStatusMap[item.id]) {
          return { ...item, isSelect: true };
        } else {
          return { ...item, isSelect: false };
        }
      });
    }
    console.log("_initStatus", _initStatus);
    return _initStatus;
  };

  const handleSearch = _searchKey => {
    if (_searchKey === "") {
      setSearchKeyWord(null);
    } else {
      setSearchKeyWord(_searchKey);
    }
  };

  const campaignChange = _campaignId => {
    setCampaignId(_campaignId);
  };

  const pageChange = (page, pageSize) => {
    setPageNo(page);
    getTableData(campaignId, searchKey, page);
  };

  const exportData = () => {
    let custId = 0;
    let _vendorId = currentVendorId;
    if (sessionStorage.getItem("loginType") !== "0") {
      custId = sessionStorage.getItem("vendorCustId");
      _vendorId = sessionStorage.getItem("vendorId");
    }

    let statusStr = "";
    statusButtons.map(item => {
      if (item.isSelect) {
        statusStr = statusStr.concat(1);
      } else {
        statusStr = statusStr.concat(0);
      }
    });

    let aLink = document.createElement("a");
    aLink.href = `${cloudPath}vendorLink/exportAmbassadorList?custId=${custId}&cpgnId=${campaignId}&searchKey=${searchKey}&vendorId=${_vendorId}&sortOption=${sortOption}&token=${sessionStorage.getItem(
      "vendorToken"
    )}&ambStatus=${statusStr}`;
    aLink.click();
  };
  const getTableData = (
    _campaignId,
    _searchKey,
    page,
    _sortOption = sortOption,
    _statusButtons = statusButtons
  ) => {
    let statusStr = "";
    _statusButtons.map(item => {
      if (item.isSelect) {
        statusStr = statusStr.concat(1);
      } else {
        statusStr = statusStr.concat(0);
      }
    });

    setSearchCondition({ searchKey: searchKey, statusButtons: _statusButtons });

    const data = {
      searchKey: _searchKey,
      pageNo: page,
      pageSize: pageSize,
      sortOption: _sortOption,
      ambStatus: statusStr
    };
    Toast.loading("Loading...", 600);
    getAmbassadorsData(currentVendorId, _campaignId, data).then(res => {
      Toast.hide();
      sessionStorage.removeItem("ambassadorCondition");
      setTableReload(1);
      if (res.data.errorCode === "200") {
        const { list } = res.data;
        const { data } = res;
        setTableData(data);
        const ambassadorList = list.map((item, index) => {
          return {
            id: index,
            activeStatus: item.activeStatus,
            cpgnIds: item.cpgnIds,
            cpgnNames: item.cpgnNames,
            custId: item.custId,
            ambassadorName: item.displayName,
            statusCode: item.statusCode,
            statusId: item.status,
            earnings: item.earnings,
            currencyCode: item.currencyCode,
            salesGenerated: item.salesGenerated,
            DirectReferrals: item.directReferrals,
            indirectReferrals: item.indirectReferrals,
            selected: false,
            imageUrl: item.imageUrl
          };
        });
        if (ambassadorList !== null) {
          setAmbassadors(ambassadorList);
          if (currentVendorId === "0" && _campaignId === 0) {
            let map = {};
            ambassadorList &&
              ambassadorList.map(item => {
                const cpgnIds = item.cpgnIds.split(",");
                const cpgnNames = item.cpgnNames.split(",");
                cpgnIds &&
                  cpgnIds.map((cpgnId, index) => {
                    map[cpgnId] = cpgnNames[index];
                  });
              });
            let campaigns = [];
            Object.keys(map).map(key => {
              campaigns.push({ id: key, name: map[key] });
            });
            setFilterCampaigns(campaigns);
          }
        } else {
          setAmbassadors([]);
          setTableData({});
        }
      } else {
        setAmbassadors([]);
        setTableData({});
        message.error(res.data.errorMessage).then(r => {});
      }
    });
  };
  // const removeAmbassadorsData()

  const removeAmbassadorsData = () => {
    const removeAmbassadors = ambassadors.filter(item => {
      return item.selected === true;
    });
    const ambassadorListu = removeAmbassadors.map(item => {
      return { cpgnIds: item.cpgnIds, custId: item.custId };
    });
    const data = {
      ambassadorList: ambassadorListu
    };
    Toast.loading("Loading...", 600);
    removeAmbassador(data).then(res => {
      Toast.hide();
      if (res.data.errorCode === "200") {
        setMoreActionAlertStep(0);
        Toast.info(t("AMBASSADORS_TOAST_REMOVE_SUCCESS"), 2);
        getTableData(campaignId, searchKey, pageNo);
      }
    });
  };

  const assignStatus = status => {
    const assignStatusAmbassadors = ambassadors.filter(item => {
      return item.selected === true;
    });
    const ambassadorListu = assignStatusAmbassadors.map(item => {
      return { cpgnIds: item.cpgnIds, custId: item.custId };
    });
    const data = {
      ambassadorList: ambassadorListu,
      status: status,
      vendorId: currentVendorId
    };
    Toast.loading("Loading...", 600);
    assignAmbassadorStatus(data).then(res => {
      Toast.hide();
      if (res.data.errorCode === "200") {
        Toast.info(t("AMBASSADORS_TOAST_ASSIGN_SUCCESS"), 2);
        setMoreActionAlertStep(0);
        getTableData(campaignId, searchKeyWord, pageNo);
      }
    });
  };

  const closeInviteUserModal = () => {
    setShowInviteUsesModal(false);
    dataInit();
  };
  const closeSignUpModal = () => {
    setShowSignUpModal(false);
    dataInit();
  };
  const dataInit = () => {
    setSubmitData(submitDataInit(submitData, paramVendorId, currentVendorId));
  };
  async function prodImageBeforeUpload(file) {
    if (file.size / 1024 / 1024 / 2 > 1) {
      Toast.info("The image must not be larger than 2M", 2);
      return false;
    }
    // setIsChange(true);
    let formData = new FormData();
    formData.append("file", file);
    Toast.loading("Loading...", 600);
    uploadProductImage(formData).then(res => {
      Toast.hide();
      if (res.data.errorCode === "200") {
        let response = res.data.response;
        setSubmitData({ ...submitData, imageUrl: response });
      } else {
        message.error(res.data.errorMessage);
      }
    });
  }

  const submitDataHandle = () => {
    submitHandle(t, submitData, currentVendorId, () => {
      if (showInviteUsesModal) {
        closeInviteUserModal();
      }
      if (showSignUpModal) {
        closeSignUpModal();
      }
    });
  };

  const submitDataHandleJustCopy = () => {
    submitHandle(t, submitData, currentVendorId, () => {});
  };

  const reSort = type => {
    if (currentVendorId !== "0" || searchKey !== "") {
      if (sortOption === type) {
        setSortOption(0);
        getTableData(campaignId, searchKeyWord, 1, 0);
      } else {
        setSortOption(type);
        getTableData(campaignId, searchKeyWord, 1, type);
      }
    }
  };

  const getModalData = type => {
    if (currentVendorId === "0") {
      Toast.info(t("AMBASSADORS_TOAST_SELECT_VENDOR"), 2);
      return;
    }
    dataInit();
    if (type === 1) {
      setShowSignUpModal(true);
    } else if (type === 2) {
      setShowInviteUsesModal(true);
    }

    if (paramVendorId === currentVendorId) {
      GenerateVendorLink(
        currentVendorId,
        t,
        submitData.campaigns,
        (response, campaigns) => {
          const vendorLink = response.vendorLink;
          const imageUrl = response.imgUrl ? response.imgUrl : "";
          setSubmitData({
            ...submitData,
            vendorLinkKey: response.key,
            vendorLink: window.location.origin + vendorLink,
            imageUrl: imageUrl,
            campaigns: campaigns,
            status: response.status ? response.status : DEFAULT_STATUS
          });
        }
      );
    } else {
      setParamVendorId(currentVendorId);
      VendorCampaigns(t, currentVendorId, newCampaigns => {
        // const submitCampaigns =
        //   newCampaigns &&
        //   newCampaigns
        //     .filter(item => {
        //       return item.selected === true;
        //     })
        //     .map(item => {
        //       return item.id;
        //     });
        GenerateVendorLink(
          currentVendorId,
          t,
          newCampaigns,
          (response, campaigns) => {
            const vendorLink = response.vendorLink;
            const imageUrl = response.imgUrl ? response.imgUrl : "";
            setSubmitData({
              ...submitData,
              campaigns: campaigns,
              vendorLinkKey: response.key,
              vendorLink: window.location.origin + vendorLink,
              imageUrl: imageUrl,
              // submitCampaigns: submitCampaigns,
              status: response.status ? response.status : DEFAULT_STATUS
            });
          }
        );
      });
    }
  };

  const statusSelect = _item => {
    const _statusButtons = statusButtons.map(item => {
      if (item.id === _item.id) {
        return { ...item, isSelect: !item.isSelect };
        // } else if(item.group && item.group === _item.group && !_item.isSelect) {
        //   return {...item, isSelect: _item.isSelect}
      } else {
        return item;
      }
    });
    setStatusButtons(_statusButtons);

    let _showUpdateButton = false;
    _statusButtons.map(item => {
      if (item.isSelect === true) {
        _showUpdateButton = true;
      }
    });
    // setShowUpdateButton(_showUpdateButton);
    setShowUpdateButton(true);
  };

  const runStatusFilter = () => {
    if (searchKey === "" && currentVendorId === "0") {
      Toast.info("Search content cannot be null", 2);
      return;
    } else {
      setVendorLoad(false);
      getTableData(campaignId, searchKey, 1);
    }
  };

  function ambassadorsData() {
    return (
      <div className="ambassadors-data">
        <div className="ambassadors-data-header">
          <div className="data-header-left">
            <div className="ambassadors-header-box" />
            <div className="ambassadors-header-text">
              {t("AMBASSADORS_NO_DATA_TITLE")}
            </div>
          </div>
          <div className="data-header-right">
            <PermissionsControl name="Operate Ambassadors">
              <a onClick={exportData}>
                <div className="data-header-export">
                  {t("AMBASSADORS_EXPORT_TEXT")}
                </div>
              </a>
            </PermissionsControl>
            <PermissionsControl name="Operate Ambassadors">
              <a onClick={() => getModalData(1)}>
                <div className="data-header-export">
                  {t("AMBASSADORS_TITLE_SIGN_UP_LINK")}
                </div>
              </a>
            </PermissionsControl>
            <PermissionsControl name="Operate Ambassadors">
              <a onClick={() => getModalData(2)}>
                <div className="data-header-add-button">
                  {t("AMBASSADORS_ADD_TEXT")}
                </div>
              </a>
            </PermissionsControl>
          </div>
        </div>
        <div className="ambassadors-status-filters">
          {statusButtons.map((item, index) => {
            return (
              <div title={item.toolTip}>
                <a onClick={() => statusSelect(item)}>
                  <div
                    key={index}
                    className={`ambassadors-status-filters-button ${
                      item.isSelect ? "button-selected" : "button-no-selected"
                    }`}
                  >
                    {item.name}
                  </div>
                </a>
                {/* <Tooltip
                title={
                  item.toolTip
                }>
               <div className="setting-tooltip">i</div>
              </Tooltip> */}
              </div>
            );
          })}

          {showUpdateButton && (
            <a onClick={runStatusFilter}>
              <div className={`button-run`}>Update</div>
            </a>
          )}
        </div>
        <div className="ambassadors-header-down-line" />
        <div className="ambassadors-search-filter-area">
          <SearchFilter
            searchValue={searchKey}
            setSearchValue={setSearchKey}
            handleSearch={handleSearch}
            showFiltersArea={showFiltersArea}
            setShowFiltersArea={setShowFiltersArea}
          />
        </div>
        {(ambassadors !== null && ambassadors.length > 0) ||
        currentVendorId !== "0" ? (
          <div className="ambassadors-filters-area">
            {showFiltersArea ? (
              <FiltersState
                campaigns={filterCampaigns}
                removeAmbassadorsData={removeAmbassadorsData}
                assignStatus={assignStatus}
                moreActionAlertStep={moreActionAlertStep}
                setMoreActionAlertStep={setMoreActionAlertStep}
                campaignId={campaignId}
                campaignChange={campaignChange}
                selectedCount={
                  ambassadors.filter(item => {
                    return item.selected === true;
                  }).length
                }
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}

        <div className="data-table-area">
          <DataTable
            vendorId={currentVendorId}
            campaignId={campaignId}
            tableData={tableData}
            pageSize={pageSize}
            ambassadors={ambassadors}
            setAmbassadors={setAmbassadors}
            pageChange={pageChange}
            reSort={reSort}
            searchCondition={searchCondition}
          />
        </div>
      </div>
    );
  }

  function ambassadorsLayout() {
    if (currentVendorId === "0") {
      return ambassadorsData();
    } else {
      if (tableReload === 0) {
        return <div style={{ backgroundColor: "#f4f4f4", height: "1000px" }} />;
      } else {
        if (
          (ambassadors === null || ambassadors.length === 0) &&
          vendorReload
        ) {
          return (
            <div className="ambassadors-no-data">
              <div className="ambassadors-no-data-header">
                <div className="ambassadors-header-box" />
                <div className="ambassadors-header-text">
                  {t("AMBASSADORS_NO_DATA_TITLE")}
                </div>
              </div>
              <div className="ambassadors-header-down-line" />
              <div className="ambassadors-no-data-image">
                <img
                  src={require("../../../images/ambassadors-no-data.png")}
                  alt=""
                />
              </div>
              <div className="ambassadors-no-data-title">
                {t("AMBASSADORS_NO_DATA_TIPS")}
              </div>
              <div className="ambassadors-no-data-description">
                {t("AMBASSADORS_NO_DATA_DESCRIPTION")}
              </div>
              <div className="ambassadors-no-data-button-area">
                {/*<a>*/}
                {/*    <div className="ambassadors-button1">Import Ambassadors</div>*/}
                {/*</a>*/}
                <PermissionsControl name="Operate Ambassadors">
                  <a onClick={() => getModalData(2)}>
                    <div className="ambassadors-button2">
                      {t("AMBASSADORS_ADD_TEXT")}
                    </div>
                  </a>
                </PermissionsControl>
              </div>
            </div>
          );
        } else {
          return ambassadorsData();
        }
      }
    }
  }
  return (
    <div className="ambassadors-main">
      {sessionStorage.getItem("loginType") === "0" && (
        <TopToolbar
          vendorList={vendorList}
          vendorId={currentVendorId}
          setVendorId={setCurrentVendorId}
        />
      )}
      <div className="ambassadors-data-layout">{ambassadorsLayout()}</div>
      <ShareSignUpLink
        showSignUpModal={showSignUpModal}
        modalClose={closeSignUpModal}
        submitHandle={submitDataHandle}
        submitData={submitData}
        setSubmitData={setSubmitData}
        submitDataHandleJustCopy={submitDataHandleJustCopy}
        prodImageBeforeUpload={prodImageBeforeUpload}
        statusList={statusList}
      />
      <InviteUsers
        showInviteUsesModal={showInviteUsesModal}
        modalClose={closeInviteUserModal}
        submitHandle={submitDataHandle}
        submitData={submitData}
        setSubmitData={setSubmitData}
        prodImageBeforeUpload={prodImageBeforeUpload}
        statusList={statusList}
      />
    </div>
  );
}

export default Ambassadors;
export const AMBASSADOR_STATUS = {
  1: "Verified Purchaser",
  2: "Nonverified Purchaser",
  3: "Paid Promotion"
};
export const DEFAULT_STATUS = 1;
