import asyncComponent from "../components/AsyncComponent";

let Notification = [
    {
        path: '/notification',
        component: asyncComponent(() => import("../views/notification/Index")),
        exact: true
    }
]

export default Notification