import asyncComponent from "../components/AsyncComponent";

let smartLink = [
  {
    path: "/smartLink",
    component: asyncComponent(() =>
      import("../views/smartLink/smartLinkCreate")
    ),
    exact: true
  },
  {
    path: "/smartLink/generate/:id?",
    component: asyncComponent(() =>
      import("../views/smartLink/smartLinkGenerate")
    ),
    exact: true
  },
  {
    path: "/smartLink/generate/show/:id?",
    component: asyncComponent(() =>
      import("../views/smartLink/smartLinkGenerateShow")
    ),
    exact: true
  },
  {
    path: "/smartLink/landing/:id",
    component: asyncComponent(() =>
      import("../views/smartLink/smartLinkLanding")
    ),
    exact: true
  },
  {
    path: "/smartLink/item",
    component: asyncComponent(() => import("../views/smartLink/smartLinkItem")),
    exact: true
  },
  {
    path: "/smartLink/manage",
    component: asyncComponent(() =>
      import("../views/smartLink/smartLinkManage")
    ),
    exact: true
  },
  {
    path: "/kwikhub/manage",
    component: asyncComponent(() =>
      import("../views/smartLink/kwikhubManager")
    ),
    exact: true
  },
  {
    path: "/kwikhub/view/:hubname?",
    component: asyncComponent(() => import("../views/smartLink/kwikhubView")),
    exact: true
  },
  {
    path: "/order/confirmation",
    component: asyncComponent(() =>
      import("../views/shoppingShareLink/post/PostConfirmation")
    ),
    exact: true
  },
  {
    path: "/order/accountsignup",
    component: asyncComponent(() =>
      import("../views/shoppingShareLink/post/AccountSignup")
    ),
    exact: true
  },
  {
    path: "/order/verification",
    component: asyncComponent(() =>
      import("../views/shoppingShareLink/post/VerificationProfile")
    ),
    exact: true
  },
  {
    path: "/shareLink/signUp",
    component: asyncComponent(() =>
      import("../vendorMoudle/containers/SignUpLinkCreateAccount")
    ),
    exact: true
  },
  {
    path: "/shareLink/signUp/step2",
    component: asyncComponent(() =>
      import("../vendorMoudle/containers/SignUpLinkCreateAccount2/index")
    ),
    exact: true
  }
];

export default smartLink;
