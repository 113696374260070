const initialState = {
    stateList: []
}

const checkoutAddress = (state = initialState, action) => {
    let stateList;
    switch (action.type) {
        case 'CHECKOUT_ADDRESS_SET_STATE_LIST':
            stateList = action.stateList;
            return Object.assign({}, state, { stateList })
        default:
            return state
    }
}

export default checkoutAddress