import Axios from "axios";
import cloudPath from "../config/cloudPath";

// export let apiCloud = urlObj[context];

const service = Axios.create({
  baseURL: cloudPath,
  timeout: 10000000
});

// service.interceptors.request.use(
//   function(config) {
//     if (window.location.href.indexOf("vendor") == -1) {
//       const dataType = typeof config.data;
//       if (config.method === "post") {
//         if (dataType == "object") {
//           config.data = {
//             portal: "WER1",
//             ...config.data
//           };
//         }
//       } else if (config.method === "get") {
//         if (dataType == "object") {
//           config.params = {
//             portal: "WER1",
//             ...config.params
//           };
//         }
//       }
//     }
//     return config;
//   },
//   function(err) {
//     return Promise.reject(err);
//   }
// );
service.interceptors.response.use(
  function(response) {
    if (response.data.errorCode === "401") {
      if (window.location.href.indexOf("vendor") > -1) {
        window.location.href = "/vendor";
      } else {
        window.localStorage.removeItem("userLoginTokenKey");
        window.localStorage.removeItem("userLoginTokenValue");
        window.location.href = "/";
      }
      return Promise.reject(response.data.errorMessage);
    } else {
      return response;
    }
  },
  function(err) {
    console.log("err", err);
    //401 402 404
    if (window.location.href.indexOf("vendor") > -1) {
      window.location.href = "/vendor";
    } else {
      window.localStorage.removeItem("userLoginTokenKey");
      window.localStorage.removeItem("userLoginTokenValue");
      window.location.href = "/";
    }
    return Promise.reject(err);
  }
);

export default service;
