import asyncComponent from "../components/AsyncComponent";
import shoppingRouter from "./shopping";
import smartLinkRouter from "./smartLink";
import kwikRouter from "./kwik";
import ketoneRouter from "./weLink";
import vendorRouter from "./vendor";
import registerRouter from "./register";
import campaigns from "./campaigns";
import sharingRouter from "./sharing";
import buyRouter from "./buy";
import settingRouter from "./setting";
import dashboardRouter from "./dashboard";
import notificationRouter from "./notification";

let routerConfig = [
  {
    path: "/checkemail",
    component: asyncComponent(() => import("../views/CheckEmail")),
  },
  {
    path: "/about",
    component: asyncComponent(() => import("../views/About")),
  },
  {
    path: "/login",
    component: asyncComponent(() => import("../views/login/Index")),
  },
  {
    path: "/signUp/login",
    component: asyncComponent(() => import("../views/SignUpLogin/Index")),
  },
  {
    path: "/findlink",
    component: asyncComponent(() => import("../views/login/findlink")),
  },
  {
    path: "/search",
    component: asyncComponent(() => import("../views/search")),
  },
  {
    path: "/registerPassword",
    component: asyncComponent(() => import("../views/registerPassword/Index")),
  },
  {
    path: "/registerPasswordSuccess",
    component: asyncComponent(() =>
      import("../views/registerPasswordSuccess/Index")
    ),
  },
  {
    path: "/landing",
    component: asyncComponent(() => import("../views/Landing")),
  },
  {
    path: "/password/reset",
    component: asyncComponent(() =>
      import("../views/shopping/ResetPassword/ResetPassword")
    ),
    exact: true,
  },
  {
    path: "/password/reset/send",
    component: asyncComponent(() =>
      import("../views/shopping/ResetPasswordSend/ResetPasswordSend")
    ),
    exact: true,
  },
  {
    path: "/sso/newage",
    component: asyncComponent(() => import("../views/newAgeSSO/Index")),
    exact: true,
  },
  {
    path: "/sso/newage/login",
    component: asyncComponent(() => import("../views/newAgeSSO/login/Index")),
    exact: true,
  },
  {
    path: "/sso/newage/confirm",
    component: asyncComponent(() =>
      import("../views/newAgeSSO/userInfoCheck/Index")
    ),
    exact: true,
  },
  {
    path: "/sso/newage/bind",
    component: asyncComponent(() =>
      import("../views/newAgeSSO/userBind/Index")
    ),
    exact: true,
  },
];
let concatRouter = routerConfig
  .concat(shoppingRouter)
  .concat(smartLinkRouter)
  .concat(kwikRouter)
  .concat(ketoneRouter)
  .concat(vendorRouter)
  .concat(registerRouter)
  .concat(campaigns)
  .concat(settingRouter)
  .concat(sharingRouter)
  .concat(buyRouter)
  .concat(dashboardRouter)
  .concat(notificationRouter);
concatRouter.push({
  path: "/",
  component: asyncComponent(() => import("../views/login/Index")),
});

export default concatRouter;
