import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

function AppContainer({ history, children }) {
  useEffect(() => {
    const AddGA = (pathname) => {
      ReactGA.initialize("UA-212493651-1");
      ReactGA.set({ js: new Date() });
      ReactGA.set({ page: pathname });
      ReactGA.pageview(pathname);
    };

    AddGA(history.location.pathname);

    const unlisten = history.listen((location) => {
      AddGA(location.pathname);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <>{children}</>;
}

export default withRouter(AppContainer);
