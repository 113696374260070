import qs from "qs";
import service from "../utils/service";
import getLanguage from "../utils/getLanguage";
import sessionCache from "../utils/sessionCache";
import { Toast, Modal } from "antd-mobile";

// 获取smartLink产品列表
export const getSmartLinkProducts = (data) => {
  data.lang = getLanguage().toLowerCase();
  return service.post(
    "/product/getAllProductList4ShareLink",
    qs.stringify(data),
    {
      headers: {
        token: sessionCache().getItem("apiToken"),
      },
    }
  );
};
// 提交smartlink
export const generateSmartLink = (data) => {
  data.portal = "WER1";
  return service
    .post("/shareLink/generateKwikLink", data, {
      headers: {
        token: sessionCache().getItem("apiToken"),
      },
    })
    .then((res) => {
      if (res.data.errorCode === "400") {
        Toast.hide();
        Modal.alert("", res.data.errorMessage, [
          {
            text: "Ok",
          },
        ]);
        return Promise.reject(res.data.errorMessage);
      } else {
        return res;
      }
    });
};
// 检查cpgnId是否可以分享
export const getKcIsPreSlCheck = (data) => {
  return service.post("/shareLink/getKcIsPreSlCheck", data);
};
// 新的创建kwikLink
export const generateKwikLink = (data) => {
  data.portal = "WER1";
  return service
    .post("/shareLink/generateKwikLink", data, {
      headers: {
        token: sessionCache().getItem("apiToken"),
      },
    })
    .then((res) => {
      if (res.data.errorCode === "400") {
        Toast.hide();
        Modal.alert("", res.data.errorMessage, [
          {
            text: "Ok",
          },
        ]);
        return Promise.reject(res.data.errorMessage);
      } else {
        return res;
      }
    });
};
// 获取smartlink列表
export const getSmartLinkList = (data) => {
  data.lang = getLanguage().toLowerCase();
  return service.post(
    "/shareLink/getShareLinkListByCustId",
    qs.stringify(data),
    {
      headers: {
        token: sessionCache().getItem("apiToken"),
      },
    }
  );
};
// 获取smartlink数据
export const getSmartLinkData = (data) => {
  // data.lang = getLanguage().toLowerCase();
  return service.get("/shareLink/getKwikLinkById", {
    params: data,
  });
};
// 创建smartlink通过orderid
export const generateShareLinkByOrderId = (data) => {
  return service.post(
    "/shareLink/generateKwickLinkByOrderId",
    qs.stringify(data)
  );
};
// 修改smartlink的linkTag
export const updateShareLink = (data) => {
  return service.post("/shareLink/updateShareLink", data);
};
// 新修改smartlink的linkTag
export const updateKwikLinkTag = (data) => {
  return service.post("/shareLink/updateKwikLinkTag", data);
};
// 获取smarthub数据
export const getHubInfoByCustId = (data) => {
  return service.get("/hub/getHubInfoByCustId", {
    params: data,
  });
};
// 验证kwikhub名字唯一
export const checkHubName = (data) => {
  return service.post("/hub/checkHubName", data);
};
// 保存或更新kwikhub信息
export const saveOrUpdateHubInfo = (data) => {
  return service.post("/hub/saveOrUpdateHubInfo", data);
};
// 获取hub信息通过gubname
export const getHubInfoByHubName = (data) => {
  return service.get("/hub/getHubInfoByHubName", {
    params: data,
  });
};
// 通过产品一组产品id获取产品信息
export const getSKUInfoByProductId = (data, countryCode) => {
  return service.get(
    "/product/getSKUInfoByProductId?" +
      data +
      "&lang=" +
      getLanguage().toLowerCase() +
      "&countryCode=" +
      (countryCode
        ? countryCode
        : sessionCache().getItem("currentSelectCountryCode"))
  );
};

// 通过产品一组产品id获取产品信息
export const getSKUInfoByProductIdForCart = (data, countryCode) => {
  return service.get(
    "/product/getSKUInfoByProductId?" +
      data +
      "&lang=" +
      getLanguage().toLowerCase() +
      "&countryCode=" +
      countryCode
  );
};

export const getSKUInfoByProductIdNew = (data) => {
  data.lang = getLanguage().toLowerCase();
  return service.post("/product/getSKUInfoByProductIdPost?", data);
};
// 通
// 通过产品一组产品id获取产品信息带token
export const getSKUInfoByProductIdWithToken = (data, countryCode) => {
  return service.get(
    "/product/getSKUInfoByProductIdWithToken?" +
      data +
      "&lang=" +
      getLanguage().toLowerCase() +
      "&countryCode=" +
      countryCode +
      "&custId=" +
      sessionCache().getItem("custId"),
    {
      headers: {
        token: sessionCache().getItem("apiToken"),
      },
    }
  );
};
// 获取vendor信息
export const getVendorInfo = (data) => {
  return service.get("/vendor/getVendorInfo", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

export const getVendorInfoForFrontend = (vendorId) => {
  return service.get("/vendor/getVuVendorInfoForFront", {
    params: { vendorId },
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

export const getVuVendorInfoForFront = (data) => {
  return service.get("/vendor/getVuVendorInfoForFront", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};
// 获取用户信息
export const getCustSimpleInfo = (data) => {
  return service.get("/common/getCustSimpleInfo", {
    params: data,
  });
};
// 获取用户信息
export const getCustVendorSimpleInfo = (data) => {
  return service.get("/common/getCustVendorSimpleInfo", {
    params: data,
  });
};
// 通过产品id获取同campaign下的产品信息
export const getProductInCpgnList = (productIds) => {
  let query = productIds.map((id) => `productIds=${id}`).join("&");
  return service.get("/product/getProductInCpgnList?" + query);
};
// 获取喜欢的产品信息
export const getVuSharingPage = (data) => {
  return service.get("/shareLink/getVuSharingPage", {
    params: data,
  });
};
// 绑定评论id
export const updateReviewList = (data) => {
  return service.post("/review/updateReviewList", data);
};

export const getVuSharingPageByCpgn = (data) => {
  return service.get("/shareLink/getVuSharingPageByCpgn", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

// 获取isVerified
export const verifiedPurchaser = (data) => {
  return service.get("/shareLink/verifiedPurchaser", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

//根据sharelink key获取当前国家
export const getVendorCountryCodeByKey = (data) => {
  return service.get("/shareLink/getVendorCountryCodeByKey", {
    params: data,
    headers: {
      token: sessionCache().getItem("apiToken"),
    },
  });
};

// 获取wer1 id
export const getVendorWer1 = () => {
  return service.get(`/vendor/getVendorWer1`);
};
