import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routerConfig from "./router";
import AppContainer from "./AppContainer";
import KCContent from "./components/KCContent";

function KCApp(props) {
  return (
    <Router>
      <KCContent>
        <AppContainer>
          <Switch>
            {routerConfig.map((data, index) => {
              return (
                <Route
                  key={index}
                  exact={data.exact}
                  path={data.path}
                  component={data.component}
                />
              );
            })}
          </Switch>
        </AppContainer>
      </KCContent>
    </Router>
  );
}

export default KCApp;
