import React from "react";
import { Toast } from "antd-mobile";
import { generateVendorLink, saveLink } from "../../requests/dashboard";
import { AMBASSADOR_STATUS } from "./index";
import {
  getCnstCampaign,
  updateCnstCampaignSimpleInfo
} from "../../requests/campaigns";
export const submitHandle = (t, submitData, currentVendorId, submitSuccess) => {
  let tempList = Object.assign([], submitData.campaigns);
  const submitCampaigns =
    submitData.campaigns &&
    submitData.campaigns
      .filter(item => {
        return item.selected === true;
      })
      .map(item => {
        return item.id;
      });
  if (submitCampaigns.length === 0) {
    Toast.info(t("AMBASSADORS_TOAST_SELECT_CAMPAIGN"), 2);
    return;
  }
  // if (submitData.imageUrl === "") {
  //     Toast.info(t('AMBASSADORS_TOAST_SELECT_IMAGE'), 2);
  //     return;
  // }
  let data = {
    cpgnIds: submitCampaigns,
    key: submitData.vendorLinkKey,
    mediastr: submitData.imageUrl,
    status: submitData.status,
    vendorId: currentVendorId * 1,
    url: submitData.vendorLink,
    emailList: submitData.emails,
    isSendEmail:
      submitData.isSendEmail == undefined || submitData.isSendEmail ? 1 : 0,
    shareType: submitData.shareType
  };
  Toast.loading("Loading...", 600);
  saveLink(data).then(res => {
    Toast.hide();
    if (res.data.errorCode === "200") {
      Toast.info(t("AMBASSADORS_TOAST_SUCCESS"), 2);
      setTimeout(() => {
        submitSuccess();
      }, 2000);
    } else {
      Toast.info(t("AMBASSADORS_TOAST_COPY_FAILED"));
    }
  });
  const cnstCampaignCommandList = [];
  tempList.map(temp => {
    let obj = {
      cpgnId: temp.id,
      inviteOption: temp.inviteOption == 1 ? 1 : 0
    };
    cnstCampaignCommandList.push(obj);
  });
  let params = {
    cnstCampaignCommandList
  };
  updateCnstCampaignSimpleInfo(params).then(res => {});
};

export const VendorCampaigns = (t, currentVendorId, success) => {
  let data = {
    vendorId: currentVendorId
  };
  Toast.loading(t("CAMPAIGNS_LOADING"), 600);
  getCnstCampaign(data).then(res => {
    Toast.hide();
    if (res.data.errorCode === "200") {
      let campaignCommandList = res.data.response.campaignCommandList;
      const newCampaigns =
        campaignCommandList &&
        campaignCommandList.map(item => {
          if (campaignCommandList.length === 1) {
            return {
              id: item.cpgnId,
              name: item.cpgnDesc,
              inviteOption: item.inviteOption,
              selected: true
            };
          } else {
            return {
              id: item.cpgnId,
              name: item.cpgnDesc,
              inviteOption: item.inviteOption,
              selected: false
            };
          }
        });
      success(newCampaigns);
    } else {
      Toast.info(res.data.errorMessage, 2);
    }
  });
};

export const GenerateVendorLink = (vendorId, t, campaigns, success) => {
  let data = {
    vendorId: vendorId
  };
  Toast.loading(t("CAMPAIGNS_LOADING"), 600);
  generateVendorLink(data).then(res => {
    Toast.hide();
    if (res.data.errorCode === "200") {
      let cpgns = res.data.cpgns?.split(",");
      let cpgnMap = {};
      if (cpgns) {
        cpgns.map(item => {
          cpgnMap[item] = true;
        });
      }

      let _campaigns = campaigns;
      _campaigns = _campaigns.map(item => {
        if (cpgnMap[item.id]) {
          return { ...item, selected: true };
        } else {
          return item;
        }
      });

      success(res.data, _campaigns);
    } else {
      Toast.info(res.data.errorMessage, 2);
    }
  });
};

export const submitDataInit = (submitData, paramVendorId, currentVendorId) => {
  const newCampaigns =
    submitData.campaigns &&
    submitData.campaigns.map(item => {
      if (submitData.campaigns.length === 1) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      return item;
    });
  // 数据初始化.
  return {
    ...submitData,
    campaigns: paramVendorId === currentVendorId ? newCampaigns : [],
    vendorLinkKey: "",
    vendorLink: "",
    // imageUrl: "",
    status: 1,
    emails: [],
    submitCampaigns: [],
    isSendEmail: true
  };
};
