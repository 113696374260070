import React, { useState, useEffect, useMemo } from "react";
import { Modal, Dropdown, Menu, Divider } from "antd";
import "./style.scss"
import { Check } from "../../../../../components/Icons/arrow-right";
import { getCnstLanguageCommandList, getCountryWarseMap } from "../../../../../utils/getCountryLanguage";
import { useHistory } from "react-router-dom";

const cnstLanguageCommandList = getCnstLanguageCommandList();
const countryWarseMap = getCountryWarseMap();

function LanguageSelect({ countryCode, language, setLanguage }) {
  const history = useHistory();
  const [languages, setLanguages] = useState([]);
  const [lang, setLang] = useState([]);

  useEffect(() => {

    setLanguages(getLocalCountryList());
    setLang(cnstLanguageCommandList);
  }, []);

  const getLocalCountryList = () => {
    let res = countryWarseMap ? Object.values(countryWarseMap).map(arr => arr[0]) : [];
    return res;
  }
  const languageInfo = useMemo(
    () => lang.find((info) => info.locationKey === language),
    [lang, language]
  );

  const LanguageLists = () => {
    return (
      <div className="languageSelectListComp">
        <Menu>
          {lang.map((langItem) => (
            <Menu.Item
              className={`${langItem.locationKey === language
                ? "languageItem active"
                : "languageItem"
                }`}
              key={langItem.locationKey}
              onClick={() => setLanguage(countryCode, langItem.locationKey)}
            >
              {langItem.languageName}
              {langItem.locationKey === language && <Check />}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    );
  };

  return (
    <Dropdown overlay={<LanguageLists />} trigger={['hover', 'click']}>
      <div className="languageSelectComp">
        <div className="linkHeaderLanguageCont">
          <div className="languageCont">
            <img src={require('../../../../../images/globe.png')} alt="" />
            <span>{languageInfo?.languageKey}</span>
          </div>
        </div>
      </div>
    </Dropdown>
  )
}

export default LanguageSelect