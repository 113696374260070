let context = process.env.REACT_APP_WEB_CONTEXT;

let urlObj = {
  // 'development': 'http://172.31.24.208:8080/',//andy
  // 'development': 'http://172.31.24.216:8080/',//wind
  development: "http://182.92.162.164:8082/",
  // development: "https://api.dev.kwik.zone/",
  test: "https://api.test.kwikclick.zone/",
  production: "https://kwikclick.com/ols/",
  cloudnative: "https://api." + process.env.REACT_APP_externalBaseDomain + "/"
};

export default urlObj[context];
