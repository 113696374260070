import React, { useEffect, useState } from "react";
import './style.scss'

const UserAvatar = ({ imageUrl, firstName, lastName, displayName, width, fontSize, background }) => {
  const [isDefault, setDefault] = useState(false)
  const [shortName, setShortName] = useState('')
  useEffect(() => {
    if (!imageUrl || imageUrl.indexOf('default_headshot') > -1) {
      setDefault(true)
      if (firstName && lastName) {
        const name = firstName[0] + lastName[0]
        setShortName(name)
      } else {
        if (displayName) {
          const nameArr = displayName.split(' ')
          let newArr = []
          nameArr.forEach((data) => {
            if (data) {
              newArr.push(data)
            }
          })
          if (newArr.length >= 2) {
            const first = newArr[0]
            let last = newArr[1]
            const name = first.slice(0, 1).toUpperCase() + last.slice(0, 1).toUpperCase()
            setShortName(name)
          } else if (newArr.length === 1) {
            const first = newArr[0]
            const name = first.slice(0, 1).toUpperCase()
            setShortName(name)
          } else {
            setDefault(false)
          }
        } else {
          setDefault(false)
        }
      }
    } else {
      setDefault(false)
    }
  }, [imageUrl, displayName, firstName])

  return (
    <div className="avatarContent" style={{ width, height: width, borderRadius: width, minWidth: width }}>
      {isDefault ? (
        <div className="default" style={{ backgroundColor: background ? background : '#00CED1' }}>
          <div className="name" style={{ fontSize: fontSize ? fontSize + 'px' : '16px', lineHeight: width + 'px' }}>{shortName}</div>
        </div>
      ) : (
        <img className="avatar" src={imageUrl} />
      )}
    </div>
  )
}

export default UserAvatar;