import { Toast } from "antd-mobile";

const initialState = {
    productData: {},
    currentProductKey: '',
    variantMap: {},
    // 产品数量
    productQuantity: 1,
    vendorCommandList: [],
    reviewCommandList: []
}

const productList = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PRODUCT_Detail':
            let productData = action.data;
            let currentProductKey = action.currentProductKey;
            let variantMap = action.variantMap;
            let vendorCommandList = action.vendorCommandList;
            return Object.assign({}, state, {
                productData,
                currentProductKey,
                variantMap,
                productQuantity: action.productQuantity === undefined ? 1 : action.productQuantity,
                vendorCommandList
            });
        case 'UPDATE_PRODUCT_QUANTITY':
            let productQuantity = state.productQuantity;
            let inv = state.productData[state.currentProductKey].inv;
            let cartDatas = action.cartDatas;
            if (action.operation === 'add') {
                let cartProdQty = cartDatas.find((d) => { return state.productData[state.currentProductKey].skuId === d.currentProductSkuId })?.qty || 0;
                if ((productQuantity + cartProdQty) < inv && inv > 0) {
                    productQuantity += 1;
                } else {
                    Toast.info(action.i18nTFun('PRODUCT_DETAIL_UPPER_LIMIT_OF_THE_INVENTORY'), 1);
                }
            } else if (action.operation === 'less') {
                if (productQuantity > 1) {
                    productQuantity -= 1;
                }
            }
            return Object.assign({}, state, {
                productQuantity
            });
        case 'PRODUCT_DETAIL/RESET_PRODUCT_NUMBER':
            return {
                ...state,
                productQuantity: action.productQuantity
            }
        case 'UPDATE_SELECT_VARIANT':
            return {
                ...state,
                currentProductKey: action.key,
                productQuantity: action.productQuantity
            }
        case 'PRODUCT_DETAIL/SET_PRODUCT_REVIEW':
            return {
                ...state,
                reviewCommandList: action.reviewCommandList
            }
        default:
            return state
    }
}

export default productList