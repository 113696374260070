// 货币符号
export let currencySymbol = {
  USD: "$",
  JPY: "¥",
  CNY: "¥",
  CAD: "$",
  GBP: "￡",
  EUR: "€"
};
// campaign对应
export let campaignMap = [
  "",
  {
    short: "ja_JP",
    word: "Japan",
    country: "JPN",
    currency: "JPY"
  },
  {
    short: "en_US",
    word: "United States",
    country: "USA",
    currency: "USD"
  },
  {
    short: "zh_CN",
    word: "China",
    country: "CHN",
    currency: "CNY"
  }
];
// 订单状态对应map
export let orderStateMap = {
  0: {
    className: "t2"
  },
  10: {
    className: "t1"
  },
  13: {
    className: "t1"
  },
  14: {
    className: "t1"
  },
  15: {
    className: "t1"
  },
  20: {
    className: "t1"
  },
  25: {
    className: "t1"
  },
  30: {
    className: "t1"
  },
  35: {
    className: "t1"
  },
  40: {
    className: "t1"
  },
  55: {
    className: "t3"
  },
  60: {
    className: "t3"
  }
};
// 订单状态对应文字
export const orderStatusDescByStatus = {
  0: "Deleted",
  10: "Pending",
  13: "BDPend",
  14: "BDInfile",
  15: "Unbalanced",
  20: "Paid",
  25: "Scheduled shipment",
  30: "Invoiced",
  35: "Partial Invoiced",
  40: "Ship Confirmed",
  55: "Refund Requested",
  60: "Refund Completed"
};
// 银行卡对应图片
export const cardTypeIconMap = {
  mastercard: require("../images/masterCardIcon.png"),
  visa: require("../images/VISAIcon.png"),
  amex: require("../images/amexIcon.jpeg"),
  discover: require("../images/discoverIcon.jpeg")
};
// 国家配置
export const countryArray = () => {
  const countryData = JSON.parse(sessionStorage.getItem("countryWarseMap"));
  const tempList = [];
  Object.values(countryData).forEach(data => {
    const newData = data[0];
    let obj = {
      country: newData.countryName,
      countryCode: newData.countryCode,
      currencyCode: newData.currencyCode,
      flag: newData.countryImageUrl
    };
    tempList.push(obj);
  });
  return tempList;
};
export const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,200}$/;

// export const countryArray = [
//   {
//     country: "United States",
//     countryCode: "USA",
//     currencyCode: "USD",
//     flag: require("../images/USAFlag.jpg"),
//   },
//   {
//     country: "Canada",
//     countryCode: "CAN",
//     currencyCode: "CAD",
//     flag: require("../images/CANFlag.png"),
//   },
//   {
//     country: "United Kingdom",
//     countryCode: "GBR",
//     currencyCode: "GBP",
//     flag: require("../images/GBRFlag.png"),
//   },
//   {
//     country: "Germany",
//     countryCode: "DEU",
//     currencyCode: "EUR",
//     flag: require("../images/DEUFlag.png"),
//   },
//   {
//     country: "Spain",
//     countryCode: "ESP",
//     currencyCode: "EUR",
//     flag: require("../images/ESPFlag.png"),
//   },
//   {
//     country: "France",
//     countryCode: "FRA",
//     currencyCode: "EUR",
//     flag: require("../images/FRAFlag.png"),
//   },
//   {
//     country: "Italy",
//     countryCode: "ITA",
//     currencyCode: "EUR",
//     flag: require("../images/ITAFlag.png"),
//   },
// ];
// 语言配置
export const languageArray = [
  {
    language: "English",
    languageCode: "en_US"
  }
];

// alpha2Code alpha3Code对应关系
export let alpha3CodeValue = {
  AU: "AUS",
  CA: "CAN",
  DE: "DEU",
  ES: "ESP",
  FR: "FRA",
  GB: "GBR",
  IT: "ITA",
  NZ: "NZL",
  US: "USA"
};

export let currentVersion = "3.0.7";
