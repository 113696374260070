import React, {useState} from 'react'
import './style.scss';

function Search({onSearch, setSearchText}) {

    const search = () =>{
        onSearch();
    }

    const onkeyup = (e) =>{
        if (e.keyCode === 13) {
            onSearch();
        }
    }
    return(
        <div className="tool-bar-search">
            <a onClick={search}><img src={require("../img/search-normal.png")} /></a>
            <input placeholder="Search or type a command" type="text" onKeyUp={onkeyup} onChange={(e)=>{setSearchText(e.target.value)}} />
        </div>
    )
}

export default Search;